import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { el, th, tr } from 'date-fns/locale';
import { EventServiceService } from 'src/app/services/event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';

@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.component.html',
  styleUrls: ['./add-listing.component.scss']
})
export class AddListingComponent implements OnInit {

  Loading: Boolean = false;

  selectedTerritories = [];
  selectedCategories = [];

  newOffer: Boolean = true;
  currentOffer: any;

  currentSection: any = "information";
  showNextSectionBt: Boolean = true;
  showPreviousSectionBt: Boolean = false;

  title: any;
  regularPrice: any;
  promoPrice: any;
  description: any;
  currency: any;
  LocationSearch: any;

  platform: any = 2;
  activate: boolean = true;

  parentCategories: any = [];
  allCategories: any = [];
  allTerritories: any = [];

  imgFile: string;
  imgSrc: any;
  selectedFileName: string;
  selectedFile: File;

  listingData: any[] = [];
  memberData: any[] = [];

  public event: EventEmitter<any> = new EventEmitter();
  
  public placeholder: string = 'Search Location';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  countriesTemplate = ['Albania', 'Andorra', 'Armenia', 'Austria', 'Azerbaijan', 'Belarus',
    'Belgium', 'Bosnia & Herzegovina', 'Bulgaria', 'Croatia', 'Cyprus', 'Canada',
    'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Georgia',
    'Germany', 'Greece', 'Hungary', 'Iceland', 'India', 'Ireland', 'Italy', 'Kosovo',
    'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Malta',
    'Moldova', 'Monaco', 'Montenegro', 'Netherlands', 'Norway', 'Poland',
    'Portugal', 'Romania', 'Russia', 'San Marino', 'Serbia', 'Slovakia', 'Slovenia',
    'Spain', 'Sweden', 'Switzerland', 'Turkey', 'Ukraine', 'United Kingdom', 'Vatican City'];


  deleteModal: BsModalRef;
  @ViewChild("modalDelete") modalDelete: ElementRef;

  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true
  };


  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    public modalService: BsModalService,
    private mixpanelService: MixpanelEventServiceService,
    public bsModalRef: BsModalRef) {

    /*  if (router.getCurrentNavigation().extras.state) {
  
        this.currentOffer = router.getCurrentNavigation().extras.state.data;
        console.log("Current Offer:" + JSON.stringify(this.currentOffer));
  
        this.newOffer = false;
  
        this.title = this.currentOffer.title;
        this.description = this.currentOffer.description;
        this.activate = this.currentOffer.isactive;
  
        if (this.currentOffer.vplatform != null) {
  
          this.platform = this.currentOffer.vplatform.id;
  
        }
  
        this.selectedCategories = this.currentOffer.vcategories;
        this.selectedTerritories = this.currentOffer.vterritories;
  
        if (this.currentOffer.media) {
  
          if (this.currentOffer.media != null) {
  
            this.imgFile = this.currentOffer.media.url;
  
          } else {
  
            this.imgFile = null;
  
          }
  
  
        }
  
      }
  
      */

    this.getAllTerritories()
    this.getAllCaregories();

  }

  ngOnInit(): void {


    if (this.listingData[0]) {

      this.currentOffer = this.listingData[0];
      //console.log("Current Offer:" + JSON.stringify(this.currentOffer));

      this.newOffer = false;

      this.title = this.currentOffer.title;
      this.description = decodeURI(this.currentOffer.description);
      this.activate = this.currentOffer.isactive;

      if (this.currentOffer.vplatform != null) {

        this.platform = this.currentOffer.vplatform.id;

      }

      this.selectedCategories = this.currentOffer.vcategories;
      this.selectedTerritories = this.currentOffer.vterritories;

      if (this.currentOffer.media) {

        if (this.currentOffer.media != null) {

          this.imgFile = this.currentOffer.media.url;

        } else {

          this.imgFile = null;

        }
      }

    }


    if (this.newOffer) {

      this.mixpanelService.trackEvent("Add New Listing");

    } else {

      this.mixpanelService.trackEvent("View Listing");

    }

  }

  hideModel(): void {

    this.bsModalRef.hide();
  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  nextSection() {

    switch (this.currentSection) {

      case 'information': {

        this.currentSection = 'audience';
        this.showPreviousSectionBt = true;

        break;
      }
      case 'audience': {

        this.currentSection = 'categories';
        this.showNextSectionBt = false;

        break;
      }
      case 'categories': {
        break;
      }

    }

  }


  previousSection() {

    switch (this.currentSection) {

      case 'information': {
        break;
      }
      case 'audience': {

        this.currentSection = 'information';
        this.showPreviousSectionBt = false;

        break;
      }
      case 'categories': {

        this.currentSection = 'audience';
        this.showNextSectionBt = true;

        break;
      }

    }

  }

  selectSection(sectionName) {

    this.currentSection = sectionName;

    if (this.currentSection == 'information') {

      this.showNextSectionBt = true;
      this.showPreviousSectionBt = false;

    } else if (this.currentSection == 'categories') {

      this.showNextSectionBt = false;
      this.showPreviousSectionBt = true;

    } else {

      this.showNextSectionBt = true;
      this.showPreviousSectionBt = true;

    }

  }


  getData(area) {

    if (area.value) {

      return area.value;

    } else {

      return area;

    }

  }

  selectTargetaudience(platformId) {

    this.platform = platformId;

  }

  AddNewLocation() {

    if (this.LocationSearch.name) {

      this.selectedTerritories.push(this.LocationSearch);
      this.LocationSearch = '';

    }

  }

  removeTerritory(index) {

    this.selectedTerritories.splice(index, 1);

  }

  getAllTerritories() {

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vterritories?name_contains=' + this.LocationSearch, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.allTerritories = data;

      //console.log("All Territories:" + JSON.stringify(this.allCategories));

    },
      (error) => {

      });

  }

  getAllCaregories() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vcategories', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      data.forEach(element => {

        element['selected'] = false;

        this.selectedCategories.forEach(category => {

          if (category.id == element.id) {

            element['selected'] = true;

          }

        })


      });


      this.parentCategories = data.filter((data) => {

        return (data.parent == null);

      });


      data = data.filter((data) => {

        return (data.parent != null);

      });



      this.allCategories = data;

      //console.log("All Categories:" + JSON.stringify(this.allCategories));

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

      });


  }


  saveChages() {

    if (this.newOffer) {

      if (this.title && this.description) {

        this.saveOffer();

      } else {

        this.toasstService.showNotification('warning', "Error", "Please enter all listing details!");

      }


    } else {

      this.updateOffer();

    }


  }


  saveOffer() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let postData = {

      "title": this.title,
      "description": encodeURI(this.description),
      "isactive": this.activate,
      "vplatform": this.platform

    }

    let selectedCategories = [];

    this.allCategories.forEach(element => {

      if (element.selected == true) {
        selectedCategories.push(element.id);
      }

    });

    this.parentCategories.forEach(element => {

      if (element.selected == true) {
        selectedCategories.push(element.id);
      }

    });

    postData["vcategories"] = selectedCategories;


    let selectedTerritories = [];

    this.selectedTerritories.forEach(element => {

      selectedTerritories.push(element.id);

    });

    postData["vterritories"] = selectedTerritories;

    console.log("Post Data:" + JSON.stringify(postData));



    this.http.post(environment.strapiUrl + '/vvendoroffers', postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.mixpanelService.trackEvent("New Listing Created");

      //console.log("Response:" + JSON.stringify(data));

      if (this.selectedFileName) {

        this.uploadOfferImage(data.id);

      } else {

        this.Loading = false;

        this.toasstService.showNotification('success', "Success", "New listing created successfully!");
        this.triggerEvent("true");
        
        this.bsModalRef.hide();

      }



    },
      (error) => {

        this.Loading = false;

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );


  }


  updateOffer() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let postData = {

      "title": this.title,
      "description": encodeURI(this.description),
      "isactive": this.activate,
      "vplatform": this.platform

    }

    let selectedCategories = [];

    this.allCategories.forEach(element => {

      if (element.selected == true) {
        selectedCategories.push(element.id);
      }

    });

    this.parentCategories.forEach(element => {

      if (element.selected == true) {
        selectedCategories.push(element.id);
      }

    });

    postData["vcategories"] = selectedCategories;


    let selectedTerritories = [];

    this.selectedTerritories.forEach(element => {

      selectedTerritories.push(element.id);

    });

    postData["vterritories"] = selectedTerritories;

    console.log("Post Data:" + JSON.stringify(postData));

    this.http.put(environment.strapiUrl + '/vvendoroffers/' + this.currentOffer.id, postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.mixpanelService.trackEvent("Listing Updated");
      //console.log("Response:" + JSON.stringify(data));

      if (this.selectedFile) {

        this.uploadOfferImage(data.id);

      } else {

        this.Loading = false;

        this.toasstService.showNotification('success', "Success", "Listing updated successfully!");

        this.triggerEvent("true");
        this.bsModalRef.hide();

      }

    }
      ,
      (error) => {

        this.Loading = false;

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }
    );


  }

  deleteOfferModel() {

    this.deleteModal = this.modalService.show(this.modalDelete, this.default);

  }

  hideDeleteModel(): void {

    this.deleteModal.hide();


  }

  deleteOffer() {

    this.deleteModal.hide();

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.delete(environment.strapiUrl + '/vvendoroffers/' + this.currentOffer.id, { headers: reqHeader }).subscribe(response => {

      this.mixpanelService.trackEvent("Listing Deleted");

      this.router.navigate(['/listings']);

    },
      (error) => {

        console.error('Error' + JSON.stringify(error.error));

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in deleting offer, Please try again!");

      }
    );


  }


  onImageChange(e) {

    this.imgFile = "";
    this.imgSrc = "";
    this.selectedFileName = "";

    this.selectedFile = e.target.files[0];

    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;

      console.log("File Name :" + JSON.stringify(e.target.files[0].name));

      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imgFile = reader.result as string;
        this.imgSrc = reader.result;
        this.selectedFileName = e.target.files[0].name;


        console.log("Image File:" + this.imgFile);
        console.log("Image Src:" + this.imgSrc);

      };

    }
  }

  uploadOfferImage(offerId) {

    const ext = this.selectedFileName.substr(this.selectedFileName.lastIndexOf('.') + 1);

    const formData = new FormData();

    let data = {
      "name": "name"
    };

    formData.append('files.Media', this.selectedFile, this.selectedFileName);
    formData.append('data', JSON.stringify({}));


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    console.log ("Image Upload Data:" + JSON.stringify(formData));
    
    this.http.put(environment.strapiUrl + '/vvendoroffers/uploadmedia/' + offerId, formData, { headers: reqHeader }).subscribe(response => {

      this.Loading = false;

      //this.imgFile = "";
      this.imgSrc = "";
      this.selectedFileName = "";
      this.selectedFile = null;

      if(this.newOffer){

        this.toasstService.showNotification('success', "Success", "New listing created successfully!");

      }else{

        this.toasstService.showNotification('success', "Success", "Listing updated successfully!");

      }
     
      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        this.Loading = false;

        console.error('Error' + JSON.stringify(error.error));

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in image upload, Please try again!");

      }
    );


  }

  createNewListing() {

    let data = this.allCategories.filter((data) => {

      return (data.selected == true);

    });

    console.log("selected Categories :" + JSON.stringify(data));

  }


}
