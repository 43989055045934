import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { el, tr } from 'date-fns/locale';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrls: ['./invite-member.component.scss']
})
export class InviteMemberComponent implements OnInit {

  token: any;
  reqHeader: any;
  postData: any;

  weddingPartId: any;

  selectedUserRole: any;

  isnewInvite: boolean = true;

  currentInviteData: any;

  name: any;
  email: any;
  phone: any;
  countryCode: any;
  memberRole: any;
  inviteMessage: any;
  pendingInvite: Boolean = false;

  weddingPartyId: any[] = [];
  memberData: any[] = [];

  public event: EventEmitter<any> = new EventEmitter();

  userData: any;

  userRoles: any = [

    { "name": "GROOM", "selected": true },
    { "name": "BESTMAN", "selected": false },
    { "name": "FRIEND", "selected": false },
    { "name": "GROOMSMAN", "selected": false },
    { "name": "RELATIVE", "selected": false },
    { "name": "BRIDE", "selected": false },
    { "name": "BRIDESMAID", "selected": false },
    { "name": "MAID OF HONOR", "selected": false }

  ];

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private mixpanelService: MixpanelEventServiceService
  ) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = localStorage.getItem('user');


  }

  ngOnInit(): void {

    if (this.weddingPartyId[0]) {

      this.weddingPartId = this.weddingPartyId[0];

      this.isnewInvite = true;

    } else if (this.memberData[0]) {

      this.currentInviteData = this.memberData[0];

      this.name = decodeURI(this.currentInviteData.wpinvite.name);
      this.email = this.currentInviteData.wpinvite.email;
      this.phone = this.currentInviteData.wpinvite.phone;
      this.inviteMessage = this.currentInviteData.wpinvite.message;

      if (this.currentInviteData.wpinvite.status == 'invited') {
        this.pendingInvite = true;
      }

      if (this.currentInviteData.prole.prole == "maid_of_honor") {

        this.selectedUserRole = "MAID OF HONOR";

      } else {

        this.selectedUserRole = this.currentInviteData.prole.trim().toUpperCase();

      }

      this.isnewInvite = false;
      this.weddingPartId = this.memberData[1];
    }

    if (this.isnewInvite) {

      this.mixpanelService.trackEvent("Invite New Member");

    } else {

      this.mixpanelService.trackEvent("View Member Invite");

    }

  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  hideNoteModel(): void {

    this.bsModalRef.hide();
  }

  selectRole(role) {

    this.selectedUserRole = role.name;

  }

  sendNewInvite() {

    if (this.name && this.email && this.countryCode && this.phone && this.selectedUserRole) {

      if (this.selectedUserRole.trim().toLowerCase() == "maid of honor") {

        this.memberRole = "maid_of_honor";

      } else {

        this.memberRole = this.selectedUserRole.trim().toLowerCase();

      }

      let postData = {
        "wp": this.weddingPartId,
        "name": encodeURI(this.name),
        "phone": "" + this.countryCode + this.phone,
        "email": this.email,
        "prole": this.memberRole
      };

      //console.log("postData" + JSON.stringify(postData));

      this.http.post(environment.strapiUrl + '/wpmembers', postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        // console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "New Member Invite Sent!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Please add all requiered data!");

    }

  }

  resendInvite() {

    if (this.name && this.email && this.countryCode && this.phone && this.selectedUserRole) {

      if (this.selectedUserRole.trim().toLowerCase() == "maid of honor") {

        this.memberRole = "maid_of_honor";

      } else {

        this.memberRole = this.selectedUserRole.trim().toLowerCase();

      }

      let postData = {
        "wp": this.weddingPartId,
        "name": encodeURI(this.name),
        "phone": "" + this.countryCode + this.phone,
        "email": this.email,
        "prole": this.memberRole
      };

      //console.log("postData" + JSON.stringify(postData));

      this.http.put(environment.strapiUrl + '/wpmembers/' + this.currentInviteData.id, postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        // console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Invite Sent!");

        this.triggerEvent("true");

        this.bsModalRef.hide();

      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Please add all requiered data!");

    }

  }

}