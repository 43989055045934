import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { AddBookedVendorComponent } from './add-booked-vendor/add-booked-vendor.component';
import { AddPlanningCategoryComponent } from './add-planning-category/add-planning-category.component';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  Loading: Boolean = false;

  token: any;
  reqHeader: any;
  postData: any;

  weddingPartId: any;

  isnewEvent: boolean = true;
  iseditEvent: boolean = false;
  newChanges: boolean = false;


  title: any;
  location: any;
  eventStartDate: any;
  eventStartTime: any;
  eventEndDate: any;
  eventEndTime: any;
  description: any;
  eventBudget: any;
  guestCount: any;
  eventID: any;

  budgetData: any;

  showPlanningProgress: Boolean = false;
  personalEvent: Boolean = false;

  currentEventData: any;

  editParticipants: Boolean = false;

  selectedParticipants: any = [];
  allParticipants: any = [];
  allMembers: any = [];

  currentTab = "Planning";

  allEventTasks: any = [];
  taskModal: BsModalRef;
  bookedVendorModel: BsModalRef;
  planningCategoriesModel: BsModalRef;

  eventData: any[] = [];
  memberData: any[] = [];

  spendingTotal: any = 0;
  spendingBudgetPercent: any = 0;

  public event: EventEmitter<any> = new EventEmitter();

  userData: any;

  wpbookedvendors: any[] = [];
  planningCategories: any[] = [];

  wpBookedVendorsDefault: any = [
    {
      "vcategory": 2,
      "isbooked": false
    },
    {
      "vcategory": 5,
      "isbooked": false
    },
    {
      "vcategory": 16,
      "isbooked": false
    }
  ]

  deleteModal: BsModalRef;
  @ViewChild("modalDelete") modalDelete: ElementRef;

  closeEventModal: BsModalRef;
  @ViewChild("modalCloseEvent") modalCloseEvent: ElementRef;

  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true
  };

  
  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = localStorage.getItem('user');


  }

  ngOnInit(): void {

    if (this.eventData[0]) {

      this.currentEventData = this.eventData[0];

      this.isnewEvent = false;

      this.title = decodeURI(this.eventData[0].title);

      if (this.currentEventData.location != null) {

        this.location = this.currentEventData.location;

      }

      this.eventStartDate = this.currentEventData.startdate;

      if (this.currentEventData.enddate && this.currentEventData.enddate != null) {

        this.eventEndDate = this.currentEventData.enddate;

      }

      if (this.currentEventData.starttime && this.currentEventData.starttime != null) {

        this.eventStartTime = this.currentEventData.starttime.substring(0, 5);;

      }

      if (this.currentEventData.endtime && this.currentEventData.endtime != null) {

        this.eventEndTime = this.currentEventData.endtime.substring(0, 5);;

      }

      if (this.currentEventData.description != null && this.currentEventData.description != '' && this.currentEventData.description != 'null') {

        this.description = decodeURI(this.currentEventData.description);

      }

      this.personalEvent = !this.currentEventData.shared;

      if (this.currentEventData.showplanningprogress == true) {

        this.showPlanningProgress = true;

        this.eventBudget = this.currentEventData.totalbudget;
        this.guestCount = this.currentEventData.guestcount;

        this.wpbookedvendors = this.currentEventData.wpbookedvendors;

        this.filterPlanningCategories( this.wpbookedvendors);

        console.log("Planning Categories:" + JSON.stringify(this.planningCategories));

        console.log("Planning Categories Length:" + this.planningCategories.length);

        this.calculateSpendingTotal();

      } else {

        this.showPlanningProgress = false;

      }

      if (this.eventData[1]) {

        this.allMembers = this.eventData[1];
        this.allParticipants = this.allMembers;

      }

      if (this.eventData[2]) {

        this.weddingPartId = this.eventData[2];

      }


    } else if (this.memberData[0]) {

      this.allMembers = this.memberData[0];
      this.allParticipants = this.allMembers;
      this.personalEvent = false;

      if (this.memberData[1]) {

        this.weddingPartId = this.memberData[1];

      }

    }

  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  filterPlanningCategories(array) {

    this.planningCategories = [];

    array.forEach(element => {

      let categoryAvailable = false;

      this.planningCategories.filter((item) => {
        
        if(element.vcategory.id == item.id ){

          categoryAvailable = true;

          if(element.isbooked == true){

            item["vendorCount"] = item["vendorCount"] + 1;
            item.vendors.push(element);

          }
         
         
        }

      });

      if (categoryAvailable == false) {

        var category = element.vcategory;

        category["vendorCount"] = 0;

        let vendors: any[] = [];

        if(element.isbooked == true){

          category["vendorCount"] = 1;

          let data = JSON.parse(JSON.stringify(element));
          vendors.push(data);

        }

        category["vendors"] = vendors;

        this.planningCategories.push(category);

      } 

    });

  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  hideEventModel(): void {

    this.bsModalRef.hide();
  }


  changeTab(tabName) {

    this.currentTab = tabName;



    switch (this.currentTab) {

      case "Planning": {

      }
      case "Notes": {
      }

      case "Tasks": {

        this.getAllEventTasks();

      }
    }



  }

  getDecodeURIData(string: any) {

    return decodeURI(string);

  }

  toggleEditParticipants() {

    this.editParticipants = !this.editParticipants;

    for (let member of this.allMembers) {

      this.currentEventData.sharedwith.forEach(sharedMember => {

        if (member.id == sharedMember.member.id) {

          this.selectedParticipants.push(member);

        }

      });

      if (member.id == this.currentEventData.wpmember.id) {

        this.selectedParticipants.push(member);

      }

    }

    this.allParticipants = this.allMembers;


    this.selectedParticipants.forEach(selectedMember => {

      for (let i = 0; i < this.allParticipants.length; i++) {

        if (this.allParticipants[i].id == selectedMember.id) {

          this.allParticipants.splice(i, 1);
          continue;
        }

      }

    });

    //console.log("Selected Participants: " + JSON.stringify(this.selectedParticipants));
    //console.log("All Participants: " + JSON.stringify(this.allParticipants));

  }

  selectParticipant(member, index) {

    this.selectedParticipants.push(member);
    this.allParticipants.splice(index, 1);

  }

  removeParticipant(member, index) {

    this.allParticipants.push(member);
    this.selectedParticipants.splice(index, 1);

  }

  calculateSpendingTotal() {

    this.spendingTotal = 0;
    //this.spendingBudgetPercent = 0;

    if (this.showPlanningProgress == true) {

      this.wpbookedvendors.forEach(bookedVendor => {

        if (bookedVendor.isbooked == true) {

          this.spendingTotal = this.spendingTotal + bookedVendor.budget;

        }
      });

      this.spendingBudgetPercent = ((this.spendingTotal * 100) / this.currentEventData.totalbudget).toFixed(0);

    }

  }

  getAllEventTasks() {

    this.http.get(environment.strapiUrl + '/wptasks?wp=' + this.weddingPartId + '&event=' + this.currentEventData.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allEventTasks = data;

    });

  }

  addNewTask(): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.weddingPartId,
        this.currentEventData.id
      ]
    };

    this.taskModal = this.modalService.show(TaskDetailsComponent, { initialState });

    this.taskModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllEventTasks();

      }
    });

  }

  editTask(taskDetails): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      taskData: [
        taskDetails,
        memberList,
        this.weddingPartId,
        this.currentEventData.id
      ]
    };

    this.taskModal = this.modalService.show(TaskDetailsComponent, { initialState });

    this.taskModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllEventTasks();

      }
    });

  }

  ChangeTaskStatus(taskDetails) {

    let postData = {
      wp: this.weddingPartId,
      completed: !taskDetails.completed,
      taskId: taskDetails.id
    };


    this.http.put(environment.strapiUrl + '/wptasks/' + taskDetails.id, postData, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('success', "Success", "Task Updated successfully!");

      this.getAllEventTasks();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );


  }


  //Planning Categories------------------------------------------

  addNewCategory(){

    const initialState = {
      planningCategoryData: [
        this.planningCategories,
        this.weddingPartId,
        this.currentEventData.id
      ]
    };

    this.planningCategoriesModel = this.modalService.show(AddPlanningCategoryComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });

    this.planningCategoriesModel.content.event.subscribe(res => {
      if (res.data != null) {

        let data = JSON.parse(res.data);

        data.forEach(Element => {

          this.wpbookedvendors.push(Element);

        });

        this.filterPlanningCategories( this.wpbookedvendors);

        //this.updateEvent(false);

      }
    });


  }
  //Booked Vendor--------------------------------------------------

  addBookedVendor() {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.weddingPartId,
        this.currentEventData.id
      ]
    };

    this.bookedVendorModel = this.modalService.show(AddBookedVendorComponent, { class: 'modal-md', ignoreBackdropClick: true });

    this.bookedVendorModel.content.event.subscribe(res => {
      if (res.data != null) {

        let bookedVendor = JSON.parse(res.data);

        let bookedVendorAvailable = false;

        this.wpbookedvendors.forEach(vendor => {

          if(vendor.vcategory.id == bookedVendor.vcategory.id && vendor.isbooked == false && bookedVendorAvailable == false){

            vendor.isbooked = true;
            vendor.vvendor = bookedVendor.vvendor;
            vendor.budget = bookedVendor.budget;

            bookedVendorAvailable = true;

          }

        });

        if(bookedVendorAvailable == false){

          this.wpbookedvendors.push(bookedVendor);
          
        }
        
        this.filterPlanningCategories(this.wpbookedvendors);
        this.calculateSpendingTotal();

        //this.updateEvent(false);

      }
    });



  }

  viewBookedVendor(bookedVendor) {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      bookedVendorData: [
        bookedVendor,
        this.weddingPartId,
        this.currentEventData.id

      ]
    };

    this.bookedVendorModel = this.modalService.show(AddBookedVendorComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });

    this.bookedVendorModel.content.event.subscribe(res => {
      if (res.data != null) {

        let data = JSON.parse(res.data);

        for (let bookedVendor of this.wpbookedvendors) {

          if (bookedVendor.id == data.id) {

            bookedVendor = data;

          }
        }

        this.filterPlanningCategories(this.wpbookedvendors);

        this.calculateSpendingTotal();

        //this.updateEvent(false);

      }
    });



  }



  //Event-----------------------------------------------------


  closeEventModel() {

    this.closeEventModal = this.modalService.show(this.modalCloseEvent, this.default);

  }

  discardChages(){

    this.closeEventModal.hide();
    this.hideEventModel();
  }

  saveChanges(){

    if(this.isnewEvent){

      this.closeEventModal.hide();
      this.createNewEvent();

    }else{

      this.closeEventModal.hide();
      this.updateEvent(true);

    }

  }

  createNewEvent() {

    if (this.title && this.eventStartDate) {

      if (!this.description) {
        this.description = '';
      }

      if (!this.location) {
        this.location = null;
      }


      let postParams = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
        latitude: null,
        longitude: null,
        location: this.location,
        startdate: this.eventStartDate,
        showplanningprogress: this.showPlanningProgress,
        includebride: true,
        wpbookedvendors: this.wpBookedVendorsDefault
      };


      if (!this.eventEndDate) {
        postParams["enddate"] = null;
      } else {
        postParams["enddate"] = this.eventEndDate;
      }

      if (!this.eventStartTime) {
        postParams["starttime"] = null;
      } else {
        postParams["starttime"] = this.eventStartTime + ":00.000";;
      }

      if (!this.eventEndTime) {
        postParams["endtime"] = null;
      } else {
        postParams["endtime"] = this.eventEndTime + ":00.000";
      }



      if (this.personalEvent) {

        postParams["shared"] = false;
        postParams["sharedwith"] = [];

      } else if (this.selectedParticipants.length > 1) {

        postParams["shared"] = true;

        let sharedwith = [];

        for (let member of this.selectedParticipants) {

          if (!(member.user.id == this.userData.id)) {

            let obj = {

              "id": member.id,
              "isadmin": true

            };

            sharedwith.push(obj);

          }

        }

        postParams["sharedwith"] = sharedwith;

      } else {

        postParams["shared"] = false;
        postParams["sharedwith"] = [];

      }


      if (this.showPlanningProgress) {


        postParams["totalbudget"] = this.eventBudget;
        postParams["guestcount"] = this.guestCount;


      } else {

        postParams["totalbudget"] = 0;
        postParams["guestcount"] = this.guestCount;

      }


      console.log("Add Event para:" + JSON.stringify(postParams));


      this.http.post(environment.strapiUrl + '/wpevents', postParams, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Event Added successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Event Start Date!");

    }

  }

  updateEvent(showToast: Boolean) {

    if (this.title && this.eventStartDate) {

      this.Loading = true;

      if (!this.description) {
        this.description = '';
      }

      if (!this.location) {
        this.location = null;
      }


      let postParams = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
        latitude: null,
        longitude: null,
        location: this.location,
        startdate: this.eventStartDate,
        showplanningprogress: this.showPlanningProgress,
        includebride: false,
        wpbookedvendors: this.wpbookedvendors
      };


      if (!this.eventEndDate) {
        postParams["enddate"] = null;
      } else {
        postParams["enddate"] = this.eventEndDate;
      }

      if (!this.eventStartTime) {
        postParams["starttime"] = null;
      } else {
        postParams["starttime"] = this.eventStartTime + ":00.000";
      }

      if (!this.eventEndTime) {
        postParams["endtime"] = null;
      } else {
        postParams["endtime"] = this.eventEndTime + ":00.000";
      }


      if (this.editParticipants) {

        if (this.personalEvent) {

          postParams["shared"] = false;
          postParams["sharedwith"] = [];

        } else if (this.selectedParticipants.length > 1) {

          postParams["shared"] = true;

          let sharedwith = [];

          for (let member of this.selectedParticipants) {

            if (!(member.user.id == this.userData.id)) {

              let obj = {

                "id": member.id,
                "isadmin": true

              };

              sharedwith.push(obj);

            }

          }

          postParams["sharedwith"] = sharedwith;

        } else {

          postParams["shared"] = false;
          postParams["sharedwith"] = [];

        }


      } else {

        if (this.personalEvent) {

          postParams["shared"] = false;
          postParams["sharedwith"] = [];

        } else {

          postParams["shared"] = this.currentEventData.shared;
          postParams["sharedwith"] = this.currentEventData.sharedwith;

        }


      }

      if (this.showPlanningProgress) {


        postParams["totalbudget"] = this.eventBudget;
        postParams["guestcount"] = this.guestCount;


      } else {

        postParams["totalbudget"] = 0;
        postParams["guestcount"] = this.guestCount;

      }

      console.error('Post Para:' + JSON.stringify(postParams));


      this.http.put(environment.strapiUrl + '/wpevents/' + this.currentEventData.id, postParams, { headers: this.reqHeader }).subscribe(response => {

        this.Loading = false;

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        if(showToast == true){

          this.toasstService.showNotification('success', "Success", "Event Updated successfully!");

        }
       
        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          this.Loading = false;

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Event Start Date!");

    }

  }

  deleteEventModel() {

    this.deleteModal = this.modalService.show(this.modalDelete, this.default);

  }

  hideDeleteModel(): void {

    this.deleteModal.hide();

  }

  deleteEvent() {

    this.deleteModal.hide();

    this.http.delete(environment.strapiUrl + '/wpevents/' + this.currentEventData.id + "?wp=" + this.weddingPartId, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('danger', "Success", "Event Deleted successfully!");

      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

}
