import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  inviteCode: any;

  constructor( private activatedRoute: ActivatedRoute, private mixpanelService: MixpanelEventServiceService) {

    this.activatedRoute.queryParams.subscribe(params => {
     
      if(params['code']){

        this.inviteCode = params['code'];
        console.log("Code: " + this.inviteCode);

      }
     
  });


   }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("User Invitation Page Visit");
    
  }
}
