import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-wedding-party',
  templateUrl: './create-wedding-party.component.html',
  styleUrls: ['./create-wedding-party.component.scss']
})
export class CreateWeddingPartyComponent implements OnInit {

  Loading: Boolean = false;

  newParty: Boolean = true;

  weddingPartyName: any;
  weddingDate: any;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private mixpanelService: MixpanelEventServiceService
    ) { }

  ngOnInit(): void {

  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }


  createWeddingParty() {

    if (this.weddingPartyName && this.weddingDate) {

      this.Loading = true;

      let token = localStorage.getItem('jwt');

      var reqHeader = new HttpHeaders({
        'Authorization': 'Bearer ' + token
      });

      let postData = {

        "title": this.weddingPartyName,
        "date": this.weddingDate,
        "dprole": "weddingplanner"

      }

      console.log("Post Data Wedding Party:" + JSON.stringify(postData));

      this.http.post(environment.strapiUrl + '/wps', postData, { headers: reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.Loading = false;

        this.toasstService.showNotification("success", "Success", "Wedding Party Created Successfully!");

        this.mixpanelService.trackEvent("New Wedding Party Created");
        
        this.triggerEvent("true");

        this.bsModalRef.hide();

      },
        (error) => {

          this.Loading = false;

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );

    } else {

      this.toasstService.showNotification('warning', "Error", "Please enter party name and date!");

    }

  }


}
