import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelEventServiceService {

  constructor() {

    mixpanel.init('a6f8fa9646b6ffe384195172191de15e');

  }

  identify(userId) {

    mixpanel.identify(userId);

  }

  setPeople(user) {

    //Mixpanel
    mixpanel.people.set(
      {
        "$name": user.name,
        "$email": user.email,
        "$phone": user.phone,
        "User Role": user.role.name
        //"$onesignal_user_id": this.res.user.id
        //"$onesignal_user_id": thmixpanelis.onesignalUserId.value
      });

  }

  trackEvent(eventName) {

    mixpanel.track("" + eventName);

  }

}
