import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tr } from 'date-fns/locale';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { ChatDetailsComponent } from '../chat-details/chat-details.component';
import { CreateChatGroupComponent } from '../create-chat-group/create-chat-group.component';
import { CreatePreferredVendorComponent } from '../create-preferred-vendor/create-preferred-vendor.component';
import { EventDetailsComponent } from '../event-details/event-details.component';
import { EventPlanningDetailsComponent } from '../event-planning-details/event-planning-details.component';
import { InviteMemberComponent } from '../invite-member/invite-member.component';
import { NoteDetailsComponent } from '../note-details/note-details.component';
import { TaskDetailsComponent } from '../task-details/task-details.component';

@Component({
  selector: 'app-view-wedding-party',
  templateUrl: './view-wedding-party.component.html',
  styleUrls: ['./view-wedding-party.component.scss']
})
export class ViewWeddingPartyComponent implements OnInit {

  Loading: Boolean = false;

  currentWeddingId: string;
  currentWedding: any;
  token: any;
  reqHeader: any;

  userData: any;

  allMembers: any = [];

  allTasks: any = [];
  allNotes: any = [];
  allEvents: any = [];
  allMessages: any = [];

  taskList: any = [];
  noteList: any = [];
  eventList: any = [];
  preferredVendorsList: any = [];

  currentTab = "Tasks";

  disabled: boolean = true;

  allList: Boolean = true;
  personalList: Boolean = false;
  sharedList: Boolean = false;

  bsModalRef: BsModalRef;
  noteModal: BsModalRef;
  eventModal: BsModalRef;
  taskModal: BsModalRef;
  chatModal: BsModalRef;
  preferredVendorModal: BsModalRef;

  @ViewChild("modalNote") modalNote: ElementRef;

  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true
  };




  isnewNote: boolean = false;
  iseditNote: boolean = false;

  title?: any;
  description?: any;
  date?: any;
  noteID: any;

  currentmodelNoteData?: any;

  notesData = [
    {
      noteId: "1",
      title: "Book Photographer",
      description: "Lorem Ipsum",
      date: "Tue Feb 09 2021 19:14:29 GMT-0500 (EST)",
      personalNote: true,
      participants: [
        {
          user_id: "8160",
          user_name: "Harshit Pethani",
          profile_pic: "2020-12-08_836_profileimage.png",
          user_email: "pethaniharshit@gmail.com",
          user_phoneno: "+12269894412",
          user_gender: ""
        },
        {
          user_id: "8560",
          user_name: "Hejan p",
          profile_pic: "2020-06-07_333_profileimage.png",
          user_email: "Hejanpatel2343@gmail.com",
          user_phoneno: "+919574284218",
          user_gender: ""
        }
      ]
    },
    {
      noteId: "2",
      title: "Write Weeding Night Speech",
      description: "lorem ipsum",
      date: "Tue Feb 09 2021 19:14:29 GMT-0500 (EST)",
      personalNote: true,
      participants: [
        {
          user_id: "8160",
          user_name: "Harshit Pethani",
          profile_pic: "2020-12-08_836_profileimage.png",
          user_email: "pethaniharshit@gmail.com",
          user_phoneno: "+12269894412",
          user_gender: ""
        }
      ]
    },

  ];



  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private route: ActivatedRoute,
    public modalService: BsModalService,
    private mixpanelService: MixpanelEventServiceService
  ) {

    this.route.params.subscribe(params => {
      this.currentWeddingId = params['id'];
    });


    this.userData = localStorage.getItem('user');

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.getWeddingParty();

    /*
    if (this.currentWedding) {
      this.getAllMembers();
      this.getAllTasks();
    }
    */


  }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("View Wedding Party");

  }

  getDecodeURIData(string: any) {

    return decodeURI(string);

  }

  replaceURLWithHTMLLinks(text) {
    var exp = '/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig';
    return text.replace(exp, "<a href='$1'>$1</a>");
  }

  changeTab(tabName) {

    this.currentTab = tabName;

    this.allList = true;
    this.personalList = false;
    this.sharedList = false;

    if (this.currentWedding) {


      switch (this.currentTab) {

        case "Tasks": {

          this.getAllTasks();

        }

        case "Notes": {

          this.getAllNotes();
        }

        case "Events": {

          this.getAllEvents();

        }

        case "Messages": {

          this.getAllMessgaes();

        }

        case "PreferredVendors": {

          this.getAllPreferredVendors();

        }
      }

    }


  }

  ChangeList(list) {

    if (list == "All") {

      this.allList = true;
      this.personalList = false;
      this.sharedList = false;

    } else if (list == "Personal") {

      this.allList = false;
      this.personalList = true;
      this.sharedList = false;

    } else if (list == "Shared") {

      this.allList = false;
      this.personalList = false;
      this.sharedList = true;

    }

    switch (this.currentTab) {

      case "Tasks": {

        this.filterTaskList();

      }

      case "Notes": {

        this.filterNoteList();

      }

      case "Events": {

        this.filterEventList();

      }

    }

  }

  getRole(role: String) {

    if (role == "weddingplanner") {

      return "Wedding Planner";

    } else {

      let first = role.substr(0, 1).toUpperCase();
      return first + role.substr(1);

    }

  }

  getWeddingParty() {

    this.Loading = true;

    this.http.get(environment.strapiUrl + '/wps/' + this.currentWeddingId, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      if (data.length > 0) {

        this.currentWedding = data[0];

        this.getAllMembers();
        this.getAllTasks();

      }
    },
      (error) => {

        this.Loading = false;

      });

  }



  getAllMembers() {

    this.http.get(environment.strapiUrl + '/wpmembers?wp=' + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allMembers = data;

      for (let member of this.allMembers) {

        if (member.user && member.user.id == this.userData.id) {

          localStorage.setItem('member', member);
          break;
        }

      }

      this.Loading = false;
    },
      (error) => {

        this.Loading = false;

      });

  }

  getAllTasks() {

    this.http.get(environment.strapiUrl + '/wptasks?wp=' + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allTasks = data;

      this.filterTaskList();

    });

  }

  filterTaskList() {

    if (this.allList) {

      this.taskList = this.allTasks;

    } else if (this.personalList) {

      this.taskList = this.allTasks.filter((task) => {

        return (task.shared != true);

      });

    } else if (this.sharedList) {

      this.taskList = this.allTasks.filter((task) => {

        return (task.shared == true);

      });

    } else {

      this.taskList = this.allTasks;

    }

  }

  getAllNotes() {

    this.http.get(environment.strapiUrl + '/wpnotes?wp=' + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allNotes = data;

      this.filterNoteList();

    });

  }

  filterNoteList() {

    if (this.allList) {

      this.noteList = this.allNotes;

    } else if (this.personalList) {

      this.noteList = this.allNotes.filter((note) => {

        return (note.shared != true);

      });

    } else if (this.sharedList) {

      this.noteList = this.allNotes.filter((note) => {

        return (note.shared == true);

      });

    } else {

      this.noteList = this.allNotes;

    }

  }


  getAllEvents() {

    this.http.get(environment.strapiUrl + '/wpevents?wp=' + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allEvents = data;

      this.filterEventList();

    });

  }

  filterEventList() {

    if (this.allList) {

      this.eventList = this.allEvents;

    } else if (this.personalList) {

      this.eventList = this.allEvents.filter((event) => {

        return (event.shared != true);

      });

    } else if (this.sharedList) {

      this.eventList = this.allEvents.filter((event) => {

        return (event.shared == true);

      });

    } else {

      this.eventList = this.allEvents;

    }

  }


  getAllMessgaes() {

    this.http.get(environment.strapiUrl + '/wpchats?wp=' + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allMessages = data;

    });

  }


  getAllPreferredVendors() {

    this.http.get(environment.strapiUrl + '/preferredvendorlists?tag=' + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.preferredVendorsList = data;

    });

  }

  //Tasks ----------------------------------------------------------------


  addNewTask(): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.currentWeddingId
      ]
    };

    this.taskModal = this.modalService.show(TaskDetailsComponent, { initialState });

    this.taskModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllTasks();

      }
    });

  }

  editTask(taskDetails): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      taskData: [
        taskDetails,
        memberList,
        this.currentWeddingId
      ]
    };

    this.taskModal = this.modalService.show(TaskDetailsComponent, { initialState });

    this.taskModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllTasks();

      }
    });

  }

  ChangeTaskStatus(taskDetails) {

    let postData = {
      wp: this.currentWedding.id,
      completed: !taskDetails.completed,
      taskId: taskDetails.id
    };


    this.http.put(environment.strapiUrl + '/wptasks/' + taskDetails.id, postData, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('success', "Success", "Task Updated successfully!");

      this.getAllTasks();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );


  }
  deleteTask(taskDetails) {

    this.http.delete(environment.strapiUrl + '/wptasks/' + taskDetails.id + "?wp=" + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('success', "Success", "Task Deleted successfully!");

      this.getAllTasks();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

  //-----------------------------------------------------------------------

  //Notes ----------------------------------------------------------------


  addNewNote(): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.currentWeddingId
      ]
    };

    this.noteModal = this.modalService.show(NoteDetailsComponent, { initialState });

    this.noteModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllNotes();

      }
    });

  }

  editNote(noteDetails): void {


    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      noteData: [
        noteDetails,
        memberList,
        this.currentWeddingId
      ]
    };


    this.noteModal = this.modalService.show(NoteDetailsComponent, { initialState });

    this.noteModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllNotes();

      }
    });

  }


  deleteNote(noteDetails) {

    this.http.delete(environment.strapiUrl + '/wpnotes/' + noteDetails.id + "?wp=" + this.currentWedding.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('success', "Success", "Note Deleted successfully!");

      this.getAllNotes();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

  //-----------------------------------------------------------------------


  //Events ----------------------------------------------------------------


  addNewEvent(): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.currentWeddingId
      ]
    };

    this.eventModal = this.modalService.show(EventDetailsComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });

    this.eventModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllEvents();

      }
    });

  }

  editEvent(eventDetails): void {


    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      eventData: [
        eventDetails,
        memberList,
        this.currentWeddingId
      ]
    };


    if (eventDetails.showplanningprogress == true) {

      this.eventModal = this.modalService.show(EventDetailsComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    } else {

      this.eventModal = this.modalService.show(EventDetailsComponent, { initialState, class: 'modal-md', ignoreBackdropClick: true });

    }


    this.eventModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllEvents();

      }
    });

  }




  //-----------------------------------------------------------------------


  //Chats ----------------------------------------------------------------

  createNewChatGroup() {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.currentWeddingId
      ]
    };

    this.chatModal = this.modalService.show(CreateChatGroupComponent, { initialState });

    this.chatModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllMessgaes();

      }
    });


  }

  viewChat(chatDetails): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      chatData: [
        chatDetails,
        memberList,
        this.currentWeddingId
      ]
    };

    this.chatModal = this.modalService.show(ChatDetailsComponent, { initialState });

    this.chatModal.content.event.subscribe(res => {

      if (res.data == "true") {

        this.getAllMessgaes();

      }

    });

  }


  //-----------------------------------------------------------------------


  //Preferred Vendors-----------------------------------------------------

  addNewPreferredVendor() {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.currentWeddingId
      ]
    };

    this.preferredVendorModal = this.modalService.show(CreatePreferredVendorComponent, { initialState, class: 'modal-md' });

    this.preferredVendorModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllPreferredVendors();

      }
    });


  }

  editPreferredVendor(vendorDetails) {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      preferredVendorData: [
        vendorDetails,
        memberList,
        this.currentWeddingId
      ]
    };


    this.preferredVendorModal = this.modalService.show(CreatePreferredVendorComponent, { initialState, class: 'modal-md' });

    this.preferredVendorModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllPreferredVendors();

      }
    });


  }
  //----------------------------------------------------------------------
  //Members---------------------------------------------------------------

  inviteNewMember() {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      weddingPartyId: [
        this.currentWeddingId
      ]
    };

    this.eventModal = this.modalService.show(InviteMemberComponent, { initialState });

    this.eventModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllMembers();

      }
    });


  }

  viewInvite(member) {

    const initialState = {
      memberData: [
        member,
        this.currentWeddingId
      ]
    };

    this.eventModal = this.modalService.show(InviteMemberComponent, { initialState });

    this.eventModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllMembers();

      }
    });


  }

}
function createPreferredVendor(createPreferredVendor: any, arg1: { initialState: { memberData: any[]; }; }): BsModalRef {
  throw new Error('Function not implemented.');
}

