import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { el } from 'date-fns/locale';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-advice-and-tips',
  templateUrl: './view-advice-and-tips.component.html',
  styleUrls: ['./view-advice-and-tips.component.scss']
})
export class ViewAdviceAndTipsComponent implements OnInit {

  Loading: Boolean = false;

  currentSection: any = "information";
  showNextSectionBt: Boolean = true;
  showPreviousSectionBt: Boolean = false;

  adviceData: any[] = [];

  currentAdviceId: any;

  selectedTerritories = [];
  selectedCategory: any;

  newAdvice: Boolean = true;
  currentAdvice: any;

  title: any;
  description: any;
  LocationSearch: any;

  activate: boolean = true;

  parentCategories: any = [];
  allCategories: any = [];
  allTerritories: any = [];

  imgFile: string;
  imgSrc: any;
  selectedFileName: string;
  selectedFile: File;

  public placeholder: string = 'Search Location';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  public event: EventEmitter<any> = new EventEmitter();
  
  deleteModal: BsModalRef;
  @ViewChild("modalDelete") modalDelete: ElementRef;

  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true
  };

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanelService: MixpanelEventServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef) {

    this.getAllTerritories()
    this.getAllCaregories();

  }

  ngOnInit(): void {


    if (this.adviceData[0]) {

      this.currentAdvice = this.adviceData[0];
      //console.log("Current Offer:" + JSON.stringify(this.currentOffer));

      this.newAdvice = false;

      this.currentAdviceId = this.currentAdvice.id;

      this.title = this.currentAdvice.title;
      this.description = decodeURI(this.currentAdvice.description);

      this.selectedCategory = this.currentAdvice.vcategory.id;
      this.selectedTerritories = this.currentAdvice.vterritories;

      if (this.currentAdvice.image) {

        if (this.currentAdvice.image != null) {

          this.imgFile = this.currentAdvice.image.url;

        } else {

          this.imgFile = null;

        }


      }

    }


    if (this.newAdvice) {

      this.mixpanelService.trackEvent("Add New Advice and Tips");

    } else {

      this.mixpanelService.trackEvent("View Advice and Tips");

    }

  }

  hideModel(): void {

    this.bsModalRef.hide();
  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  selectCategory(categoryId){

    this.selectedCategory = categoryId;

  }

  nextSection() {

    switch (this.currentSection) {

      case 'information': {

        this.currentSection = 'categories';
        this.showPreviousSectionBt = true;
        this.showNextSectionBt = false;

        break;
      }
      case 'categories': {
        break;
      }

    }

  }


  previousSection() {

    switch (this.currentSection) {

      case 'information': {
        break;
      }
      case 'categories': {

        this.currentSection = 'information';
        this.showNextSectionBt = true;
        this.showPreviousSectionBt = false;

        break;
      }

    }

  }

  selectSection(sectionName) {

    this.currentSection = sectionName;

    if (this.currentSection == 'information') {

      this.showNextSectionBt = true;
      this.showPreviousSectionBt = false;

    }  else {

      this.showNextSectionBt = false;
      this.showPreviousSectionBt = true;

    }

  }


  AddNewLocation() {

    if (this.LocationSearch.name) {

      this.selectedTerritories.push(this.LocationSearch);
      this.LocationSearch = '';

    }

  }

  removeTerritory(index) {

    this.selectedTerritories.splice(index, 1);

  }

  getAdviceAndTipsData() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vadviceandtips/' + this.currentAdviceId, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.Loading = false;

      //console.log("Response:" + JSON.stringify(data));

      this.currentAdvice = data;

      this.title = this.currentAdvice.title;
      this.description = this.currentAdvice.description;

      this.selectedCategory = this.currentAdvice.vcategory;
      this.selectedTerritories = this.currentAdvice.vterritories;

      if (this.currentAdvice.image) {

        if (this.currentAdvice.image != null) {

          this.imgFile = this.currentAdvice.image.url;

        } else {

          this.imgFile = null;

        }


      }

    },
      (error) => {

        this.Loading = false;

        //console.error('Error' + error);

      }
    );


  }

  getAllTerritories() {

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vterritories?name_contains=' + this.LocationSearch, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.allTerritories = data;

      console.log("All Territories:" + JSON.stringify(this.allCategories));

    },
    (error) => {

      this.Loading = false;

    });

  }

  getAllCaregories() {

    this.Loading = true;


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vcategories', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      data.forEach(element => {

        element['selected'] = false;

        if (this.selectedCategory && this.selectedCategory.id == element.id) {

          element['selected'] = true;

        }

      });


      this.parentCategories = data.filter((data) => {

        return (data.parent == null);

      });


      data = data.filter((data) => {

        return (data.parent != null);

      });



      this.allCategories = data;

      console.log("All Categories:" + JSON.stringify(this.allCategories));

      this.Loading = false;

    },
    (error) => {

     this.Loading = false;

    });


  }

  onImageChange(e) {

    this.imgFile = "";
    this.imgSrc = "";
    this.selectedFileName = "";

    this.selectedFile = e.target.files[0];

    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;

      console.log("File Name :" + JSON.stringify(e.target.files[0].name));

      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imgFile = reader.result as string;
        this.imgSrc = reader.result;
        this.selectedFileName = e.target.files[0].name;


        console.log("Image File:" + this.imgFile);
        console.log("Image Src:" + this.imgSrc);

      };

    }
  }

  saveChages() {

    if (this.newAdvice) {

      if (this.title && this.description) {


        this.createTipsAndAdvice();

      } else {

        this.toasstService.showNotification('warning', "Error", "Please enter all listing details!");

      }


    } else {

      this.updateTipsAndAdvice();
    }


  }

  uploadOfferImage(offerId) {

    const ext = this.selectedFileName.substr(this.selectedFileName.lastIndexOf('.') + 1);

    const formData = new FormData();

    let data = {
      "name": "name"
    };

    formData.append('files.Media', this.selectedFile, this.selectedFileName);
    formData.append('data', JSON.stringify({}));


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.put(environment.strapiUrl + '/vadviceandtips/uploadmedia/' + offerId, formData, { headers: reqHeader }).subscribe(response => {

      //this.imgFile = "";
      this.imgSrc = "";
      this.selectedFileName = "";
      this.selectedFile = null;

      this.Loading = false;

      if(this.newAdvice){

        this.toasstService.showNotification('success', "Success", "New Advice and Tips created successfully!");

      }else{

        this.toasstService.showNotification('success', "Success", "Advice and Tips updated successfully!");

      }

      this.triggerEvent("true");
      
      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + JSON.stringify(error.error));

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in image upload, Please try again!");

      }
    );


  }

  createTipsAndAdvice() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let postData = {

      "title": this.title,
      "description": encodeURI(this.description),
      "isactive": this.activate

    }

    let selectedCategories = [];

    if(this.selectedCategory){

      selectedCategories.push(this.selectedCategory);

    }

    postData["vcategory"] = selectedCategories;

    let selectedTerritories = [];

    this.selectedTerritories.forEach(element => {

      selectedTerritories.push(element.id);

    });

    postData["vterritories"] = selectedTerritories;

    console.log("Post Data:" + JSON.stringify(postData));



    this.http.post(environment.strapiUrl + '/vadviceandtips', postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      if (this.selectedFileName) {

        this.uploadOfferImage(data.id);

      } else {

        this.Loading = false;

        this.toasstService.showNotification('success', "Success", "New Advice and Tips created successfully!");
        this.triggerEvent("true");
        
        this.bsModalRef.hide();

      }



    },
      (error) => {

        this.Loading = false;

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );


  }

  updateTipsAndAdvice() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let postData = {

      "title": this.title,
      "description": encodeURI(this.description),
      "isactive": this.activate

    }

    let selectedCategories = [];

    if(this.selectedCategory){

      selectedCategories.push(this.selectedCategory);

    }

    postData["vcategory"] = selectedCategories;


    let selectedTerritories = [];

    this.selectedTerritories.forEach(element => {

      selectedTerritories.push(element.id);

    });

    postData["vterritories"] = selectedTerritories;

    console.log("Post Data:" + JSON.stringify(postData));

    this.http.put(environment.strapiUrl + '/vadviceandtips/' + this.currentAdviceId, postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      if (this.selectedFile) {

        this.uploadOfferImage(data.id);

      } else {

        this.Loading = false;

        this.toasstService.showNotification('success', "Success", "Advice and Tips updated successfully!");
        this.triggerEvent("true");
        
        this.bsModalRef.hide();

      }

    }
      ,
      (error) => {

        this.Loading = false;

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }
    );


  }


  deleteAdviceAndTipModel() {

    this.deleteModal = this.modalService.show(this.modalDelete, this.default);

  }

  hideAdviceAndTipModel(): void {

    this.deleteModal.hide();


  }

  deleteAdviceAndTip() {

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.delete(environment.strapiUrl + '/vadviceandtips/' + this.currentAdvice.id, { headers: reqHeader }).subscribe(response => {

      this.deleteModal.hide();

      this.toasstService.showNotification('danger', "Success", "Advice and Tip deleted successfully!");
      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + JSON.stringify(error.error));

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in deleting Advice and Tips, Please try again!");

      }
    );


  }

}
