import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-booked-vendor',
  templateUrl: './add-booked-vendor.component.html',
  styleUrls: ['./add-booked-vendor.component.scss']
})
export class AddBookedVendorComponent implements OnInit {

  userData: any;

  Loading: Boolean = false;
  newBookedVendor: Boolean = true;

  token: any;
  reqHeader: any;
  postData: any;

  searchText: any = "";

  selectedCategoryName: any;
  selectedVendorName: any;

  selectedCategory: any;
  selectedVendor: any;
  vendorBudget: any;

  currentBoookedVendorData: any;

  currentSection: any = "categories";

  parentCategories: any[] = [];
  allCategories: any[] = [];

  totalVendorsCount: any = 0;
  allVendors: any = [];

  bookedVendorData: any[] = [];

  public event: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {

    this.userData = JSON.parse(localStorage.getItem('user'));

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

  }

  ngOnInit(): void {

    if (this.bookedVendorData[0]) {

      this.currentBoookedVendorData = this.bookedVendorData[0];

      this.newBookedVendor = false;

      this.vendorBudget = this.currentBoookedVendorData.budget;
      this.selectedCategoryName = this.currentBoookedVendorData.vcategory.name;
      this.selectedVendorName = this.currentBoookedVendorData.vvendor.name;

      this.selectedCategory = this.currentBoookedVendorData.vcategory;
      this.selectedVendor = this.currentBoookedVendorData.vvendor;

      this.currentSection = '';

    }

    this.getAllCaregories();
    this.getAllVendors();

  }

  hideModel(): void {

    this.bsModalRef.hide();

  }

  selectSection(sectionName) {

    this.currentSection = sectionName;

  }
  //Categories---------------------------------------------------
  getAllCaregories() {

    this.Loading = true;


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vcategories', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      data.forEach(element => {

        element['selected'] = false;

        if (this.selectedCategory && this.selectedCategory.id == element.id) {

          element['selected'] = true;

        }

      });


      this.parentCategories = data.filter((data) => {

        return (data.parent == null);

      });


      data = data.filter((data) => {

        return (data.parent != null);

      });



      this.allCategories = data;

      console.log("All Categories:" + JSON.stringify(this.allCategories));

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

      });

  }

  selectCategory(category) {

    this.selectedCategory = category;
    this.selectedCategoryName = category.name;

    this.currentSection = 'vendors';

  }

  //Vendors------------------------------------------------------------

  getAllVendors() {

    this.Loading = true;

    this.http.get(environment.strapiUrl + '/preferredvendorlists?tag=' + this.userData.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allVendors = data;
      //this.totalVendorsCount = data.total;

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

      });

  }

  selectVendor(vendor) {

    this.selectedVendor = vendor;
    this.selectedVendorName = vendor.name;

    this.currentSection = '';

  }


  //Booked Vendors--------------------------------------------------------

  addNewBookedVendor() {

    let bookedVendor = {
      "vcategory": this.selectedCategory,
      "vvendor": this.selectedVendor,
      "isbooked": true,
      "budget": this.vendorBudget
    }

    this.triggerEvent(JSON.stringify(bookedVendor));

    this.toasstService.showNotification('success', "Success", "Vendor Added Successfully!");

    this.bsModalRef.hide();

  }


  updateNewBookedVendor() {

    this.currentBoookedVendorData.budget = this.vendorBudget;
    this.currentBoookedVendorData.vcategory = this.selectedCategory;
    this.currentBoookedVendorData.vvendor = this.selectedVendor;

    this.triggerEvent(JSON.stringify(this.currentBoookedVendorData));

    this.toasstService.showNotification('success', "Success", "Vendor Updated Successfully!");

    this.bsModalRef.hide();

  }

  deleteBookedVendor(){

    this.currentBoookedVendorData.budget = null;
    this.currentBoookedVendorData.vcategory = this.selectedCategory;
    this.currentBoookedVendorData.vvendor = null;
    this.currentBoookedVendorData.isbooked = false;

    this.triggerEvent(JSON.stringify(this.currentBoookedVendorData));

    this.toasstService.showNotification('danger', "Success", "Vendor Deleted Successfully!");

    this.bsModalRef.hide();

  }
  

  triggerEvent(item: string) {

    this.event.emit({ data: item, res: 200 });

  }
}
