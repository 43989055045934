import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-switch-role',
  templateUrl: './switch-role.component.html',
  styleUrls: ['./switch-role.component.scss']
})
export class SwitchRoleComponent implements OnInit {

  Loading: boolean = false;

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private mixpanelService: MixpanelEventServiceService,
    public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  hideModel(): void {

    this.bsModalRef.hide();
  }

  upgradeRole() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let userData = JSON.parse(localStorage.getItem('user'));

    let postData = {

      "username": userData.username,
      "email": userData.email,
      "phone": userData.phone,
      "role": "weddingplanner"

    }

    this.http.put(environment.strapiUrl + '/engaged/update', postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      
      this.upgradeRole2();

      //this.bsModalRef.hide();

    },
      (error) => {

        this.Loading = false;

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      });
  }

  upgradeRole2() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let userData = JSON.parse(localStorage.getItem('user'));

    let postData = {

      "username": userData.username,
      "email": userData.email,
      "phone": userData.phone,
      "role": "weddingplanner"

    }

    this.http.put(environment.strapiUrl + '/engaged/update', postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      localStorage.setItem('user', receivedData);

      this.Loading = false;

      this.toasstService.showNotification('success', "Success", "Upgraded to Weddign Planner Role!");

      window.location.reload();

      this.bsModalRef.hide();


    },
      (error) => {

        this.Loading = false;

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      });
  }


}
