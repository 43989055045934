import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToastServiceService {

  constructor(private toastr: ToastrService) {

  }


  showNotification(type,title, message) {
    const color = Math.floor(Math.random() * 5 + 1);
    if (type === 'default') {
      this.toastr.show(
        '<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">' + title + '</span> <span data-notify="message">' + message + '</span></div>',
        '',
        {
          timeOut: 2000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-default alert-notify",
        }
      );
    }
    if (type === 'danger') {
      this.toastr.show(
        '<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">' + title + '</span> <span data-notify="message">' + message + '</span></div>',
        '',
        {
          timeOut: 2000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-danger alert-notify",
        }
      );
    }
    if (type === 'success') {
      this.toastr.show(
        '<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">' + title + '</span> <span data-notify="message">' + message + '</span></div>',
        '',
        {
          timeOut: 2000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-success alert-notify",
        }
      );
    }
    if (type === 'warning') {
      this.toastr.show(
        '<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">' + title + '</span> <span data-notify="message">' + message + '</span></div>',
        '',
        {
          timeOut: 2000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-warning alert-notify",
        }
      );
    }
    if (type === 'info') {
      this.toastr.show(
        '<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">' + title + '</span> <span data-notify="message">' + message + '</span></div>',
        '',
        {
          timeOut: 2000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: "ngx-toastr alert alert-dismissible alert-info alert-notify",
        }
      );
    }
  }

}
