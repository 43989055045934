import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-web-layout",
  templateUrl: "./web-layout.component.html",
  styleUrls: ["./web-layout.component.scss"]
})
export class WebLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;

  constructor(private router: Router) {}

  ngOnInit() {
  
  }
  ngOnDestroy() {
    
  }
}
