import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { CreatePreferredVendorComponent } from '../create-preferred-vendor/create-preferred-vendor.component';

@Component({
  selector: 'app-preferred-vendor-lists',
  templateUrl: './preferred-vendor-lists.component.html',
  styleUrls: ['./preferred-vendor-lists.component.scss']
})
export class PreferredVendorListsComponent implements OnInit {

  loading: Boolean = false;

  token: any;
  reqHeader: any;
  userData: any;

  preferredVendorsList: any = [];
  newPreferredVendorModal: BsModalRef;
  editPreferredVendorModal: BsModalRef;

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private route: ActivatedRoute,
    public modalService: BsModalService,
    private mixpanelService: MixpanelEventServiceService) {


    this.userData = JSON.parse(localStorage.getItem('user'));

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.getAllPreferredVendors();

  }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("View All Prefered Vendors");

  }

  getAllPreferredVendors() {

    this.loading = true;

    this.http.get(environment.strapiUrl + '/preferredvendorlists?tag=' + this.userData.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.preferredVendorsList = data;

      this.loading = false;
    },
    (error) => {

      this.loading = false;

      console.error('Error' + error);
      this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

    });

  }

  addNewPreferredVendor() {

    const initialState = {
      memberData: [
      ]
    };

    this.newPreferredVendorModal = this.modalService.show(CreatePreferredVendorComponent,{ initialState, class: 'modal-lg' });

    this.newPreferredVendorModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllPreferredVendors();

      }
    });


  }

  editPreferredVendor(vendorDetails){

    const initialState = {
      preferredVendorData: [
        vendorDetails
      ]
    };


    this.editPreferredVendorModal = this.modalService.show(CreatePreferredVendorComponent, { initialState, class: 'modal-md' });

    this.editPreferredVendorModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllPreferredVendors();

      }
    });

  }

  deletePreferredVendor(vendor) {

    this.http.delete(environment.strapiUrl + '/preferredvendorlists/' + vendor.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      //console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('danger', "Success", "Vendor Deleted successfully!");

      this.mixpanelService.trackEvent("Preferred Vendor Deleted");

      this.getAllPreferredVendors();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

}
