import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';

@Component({
  selector: 'app-weddingpartyinvites',
  templateUrl: './weddingpartyinvites.component.html',
  styleUrls: ['./weddingpartyinvites.component.scss']
})
export class WeddingpartyinvitesComponent implements OnInit {

  inviteCode: any;

  constructor( private activatedRoute: ActivatedRoute, private mixpanelService: MixpanelEventServiceService) {

    this.activatedRoute.queryParams.subscribe(params => {
     
      if(params['code']){

        this.inviteCode = params['code'];
        console.log("Code: " + this.inviteCode);

      }
     
  });


   }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("User Invitation Page Visit");
    
  }

}
