import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { WebLayoutComponent } from "./pages/website/web-layout.component";
import { PresentationModule } from "./pages/presentation/presentation.module";

import { BrowserModule } from '@angular/platform-browser';
import { ComponentsModule } from "./components/components.module";

import { AppRoutingModule } from './app-routing.module';
import { ReportsComponent } from './pages/reports/reports.component';
import { EventsComponent } from './pages/events/events.component';
import { TipsComponent } from './pages/tips/tips.component';
import { ListingsComponent } from './pages/listings/listings.component';
import { NotesComponent } from './pages/notes/notes.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AddListingComponent } from './pages/add-listing/add-listing.component';

import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

import { NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { HowWeWorkComponent } from './pages/how-we-work/how-we-work.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';

import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { GettingStartedComponent } from './pages/getting-started/getting-started.component';
import { WeddingpartyinvitesComponent } from './pages/weddingpartyinvites/weddingpartyinvites.component';
import { WeddingPartiesComponent } from './pages/wedding-parties/wedding-parties.component';
import { CreateWeddingPartyComponent } from './pages/create-wedding-party/create-wedding-party.component';
import { ViewWeddingPartyComponent } from './pages/view-wedding-party/view-wedding-party.component';
import { NoteDetailsComponent } from './pages/note-details/note-details.component';
import { TaskDetailsComponent } from './pages/task-details/task-details.component';
import { ChatDetailsComponent } from './pages/chat-details/chat-details.component';
import { EmailUnsubscribeComponent } from './pages/email-unsubscribe/email-unsubscribe.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { InviteMemberComponent } from './pages/invite-member/invite-member.component';
import { EventPlanningDetailsComponent } from './pages/event-planning-details/event-planning-details.component';
import { PreferredVendorListsComponent } from './pages/preferred-vendor-lists/preferred-vendor-lists.component';
import { AdviceAndTipsComponent } from './pages/advice-and-tips/advice-and-tips.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { ViewAdviceAndTipsComponent } from './pages/view-advice-and-tips/view-advice-and-tips.component';
import { ViewPromotionComponent } from './pages/view-promotion/view-promotion.component';
import { CreateChatGroupComponent } from './pages/create-chat-group/create-chat-group.component';
import { CreatePreferredVendorComponent } from './pages/create-preferred-vendor/create-preferred-vendor.component';
import { SwitchRoleComponent } from './pages/switch-role/switch-role.component';
import { AddBookedVendorComponent } from './pages/event-details/add-booked-vendor/add-booked-vendor.component';
import { AddPlanningCategoryComponent } from './pages/event-details/add-planning-category/add-planning-category.component';
import { InviteComponent } from './pages/invite/invite.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    WebLayoutComponent,
    ReportsComponent,
    EventsComponent,
    TipsComponent,
    ListingsComponent,
    NotesComponent,
    UserProfileComponent,
    AddListingComponent,
    GettingStartedComponent,
    WeddingpartyinvitesComponent,
    InviteComponent,
    WeddingPartiesComponent,
    CreateWeddingPartyComponent,
    ViewWeddingPartyComponent,
    NoteDetailsComponent,
    TaskDetailsComponent,
    ChatDetailsComponent,
    EmailUnsubscribeComponent,
    EventDetailsComponent,
    InviteMemberComponent,
    EventPlanningDetailsComponent,
    PreferredVendorListsComponent,
    AdviceAndTipsComponent,
    PromotionsComponent,
    ViewAdviceAndTipsComponent,
    ViewPromotionComponent,
    CreateChatGroupComponent,
    CreatePreferredVendorComponent,
    SwitchRoleComponent,
    AddBookedVendorComponent,
    AddPlanningCategoryComponent,
    InviteComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TagInputModule,
    PresentationModule,
    BrowserModule,
    AutocompleteLibModule,
    NgxIntlTelInputModule
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
