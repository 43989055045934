import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { WebLayoutComponent } from "./pages/website/web-layout.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";
import { ManagementComponent } from "./pages/presentation/management/management.component";
import { MarketingComponent } from "./pages/presentation/marketing/marketing.component";
import { VendorsComponent } from "./pages/presentation/vendors/vendors.component";
import { WeddingPlannerLandingComponent } from "./pages/presentation/wedding-planner-landing/wedding-planner-landing.component";
import { ReportsComponent } from "./pages/reports/reports.component";
import { EventsComponent } from "./pages/events/events.component";
import { TipsComponent } from "./pages/tips/tips.component";
import { ListingsComponent } from "./pages/listings/listings.component";
import { NotesComponent } from "./pages/notes/notes.component";
import { InviteMemberComponent } from "./pages/invite-member/invite-member.component"
import { NoteDetailsComponent } from "./pages/note-details/note-details.component";
import {EventDetailsComponent} from "./pages/event-details/event-details.component";
import {EventPlanningDetailsComponent} from "./pages/event-planning-details/event-planning-details.component";
import { TaskDetailsComponent } from "./pages/task-details/task-details.component";
import { CreateChatGroupComponent } from "./pages/create-chat-group/create-chat-group.component";
import { ChatDetailsComponent } from "./pages/chat-details/chat-details.component";
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { AddListingComponent } from "./pages/add-listing/add-listing.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { GettingStartedComponent } from "./pages/getting-started/getting-started.component";
import { WeddingpartyinvitesComponent } from "./pages/weddingpartyinvites/weddingpartyinvites.component";
import { WeddingPartiesComponent } from "./pages/wedding-parties/wedding-parties.component";
import { CreateWeddingPartyComponent } from "./pages/create-wedding-party/create-wedding-party.component";
import { ViewWeddingPartyComponent } from "./pages/view-wedding-party/view-wedding-party.component";
import { PreferredVendorListsComponent} from "./pages/preferred-vendor-lists/preferred-vendor-lists.component";
import { CreatePreferredVendorComponent} from "./pages/create-preferred-vendor/create-preferred-vendor.component";
import { AdviceAndTipsComponent} from "./pages/advice-and-tips/advice-and-tips.component";
import { ViewAdviceAndTipsComponent} from "./pages/view-advice-and-tips/view-advice-and-tips.component";
import { PromotionsComponent} from "./pages/promotions/promotions.component";
import { ViewPromotionComponent} from "./pages/view-promotion/view-promotion.component";
import { SwitchRoleComponent } from "./pages/switch-role/switch-role.component";
import { AddBookedVendorComponent } from "./pages/event-details/add-booked-vendor/add-booked-vendor.component";
import { AddPlanningCategoryComponent } from "./pages/event-details/add-planning-category/add-planning-category.component";
import { InviteComponent } from "./pages/invite/invite.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "web",
    pathMatch: "full"
  },
  {
    path: "presentation",
    component: PresentationComponent
  },
  {
    path: "web",
    component: WebLayoutComponent,
    loadChildren:
      "./pages/website/web-layout.module#WebLayoutModule"
  },
  {
    path: "management",
    component: ManagementComponent
  },
  {
    path: "marketing",
    component: MarketingComponent
  },
  {
    path: "weddingPlanner",
    component: WeddingPlannerLandingComponent
  },
  {
    path: "vendors",
    component: VendorsComponent
  },
  {
    path: "weddingpartyinvites",
    component: WeddingpartyinvitesComponent
  },
  {
    path: "invites",
    component: InviteComponent
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "dashboards",
        loadChildren: "./pages/dashboards/dashboards.module#DashboardsModule"
      },
      {
        path: "components",
        loadChildren: "./pages/components/components.module#ComponentsModule"
      },
      {
        path: "forms",
        loadChildren: "./pages/forms/forms.module#FormsModules"
      },
      {
        path: "tables",
        loadChildren: "./pages/tables/tables.module#TablesModule"
      },
      {
        path: "maps",
        loadChildren: "./pages/maps/maps.module#MapsModule"
      },
      {
        path: "widgets",
        loadChildren: "./pages/widgets/widgets.module#WidgetsModule"
      },
      {
        path: "charts",
        loadChildren: "./pages/charts/charts.module#ChartsModule"
      },
      {
        path: "calendar",
        loadChildren: "./pages/calendar/calendar.module#CalendarModule"
      },
      {
        path: "reports",
        component: ReportsComponent
      },
      {
        path: "events",
        component: EventsComponent
      },
      {
        path: "eventDetails",
        component: EventDetailsComponent
      },
      {
        path: "addBookedVendor",
        component: AddBookedVendorComponent
      },
      {
        path: "addPlanningCategories",
        component: AddPlanningCategoryComponent
      },
      {
        path: "eventPlanningDetails",
        component: EventPlanningDetailsComponent
      },
      {
        path: "tips",
        component: TipsComponent
      },
      {
        path: "listings",
        component: ListingsComponent
      },
      {
        path: "weddingParties",
        component: WeddingPartiesComponent
      },
      {
        path: "createWeddingParty",
        component: CreateWeddingPartyComponent
      },
      {
        path: "viewWeddingParty/:id",
        component: ViewWeddingPartyComponent
      },
      {
        path: "preferredVendorLists",
        component: PreferredVendorListsComponent
      },
      {
        path: "createPreferredVendor",
        component: CreatePreferredVendorComponent
      },
      {
        path: "adviceAndTips",
        component: AdviceAndTipsComponent
      },
      {
        path: "viewAdviceAndTips/:id",
        component: ViewAdviceAndTipsComponent
      },
      {
        path: "promotions",
        component: PromotionsComponent
      },{
        path: "viewPromotion/:id",
        component: ViewPromotionComponent
      },
      {
        path: "notes",
        component: NotesComponent
      },
      {
        path: "noteDetails",
        component: NoteDetailsComponent
      },
      {
        path: "taskDetails",
        component: TaskDetailsComponent
      },
      {
        path: "createChatGrooup",
        component: CreateChatGroupComponent
      },
      {
        path: "chatDetails",
        component: ChatDetailsComponent
      },
      {
        path: "inviteMember",
        component: InviteMemberComponent
      },
      {
        path: "userProfile",
        component: UserProfileComponent
      },
      {
        path: "addListing",
        component: AddListingComponent
      },
      {
        path: "gettingStarted",
        component: GettingStartedComponent
      },
      {
        path: "switchRole",
        component: SwitchRoleComponent
      }

    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "presentation"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
