import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-unsubscribe',
  templateUrl: './email-unsubscribe.component.html',
  styleUrls: ['./email-unsubscribe.component.scss']
})
export class EmailUnsubscribeComponent implements OnInit {

  id: any;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private toasstService: ToastServiceService) {

    this.activatedRoute.queryParams.subscribe(params => {

      if (params['op']) {

        this.id = params['op'];
        //console.log("Code: " + this.code);

      }

    });

  }

  ngOnInit(): void {
  }

  unsubscribeEmail() {

    if (this.id) {

      this.http.put(environment.strapiUrl + '/vvendors/unsubscribe/'+ this.id, {}, {}).subscribe(response => {

        let receivedData = response;

        //console.log("Response:" + JSON.stringify(receivedData));

        this.toasstService.showNotification('success', "Success", "Unsubscribed from email list!");

      },
        (error) => {

          //console.error('Error' + error);
          //let errorData = error.error;
          //this.toasstService.showNotification('danger', "Error", "Something went wrong!");

        });

    } else {

    }

  }

}
