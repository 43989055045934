import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { CreateWeddingPartyComponent } from '../create-wedding-party/create-wedding-party.component';

@Component({
  selector: 'app-wedding-parties',
  templateUrl: './wedding-parties.component.html',
  styleUrls: ['./wedding-parties.component.scss']
})
export class WeddingPartiesComponent implements OnInit {

  Loading: Boolean = false;

  allWeddingParties = [];

  newWeddingPartyModal: BsModalRef;

  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    public modalService: BsModalService,
    private mixpanelService: MixpanelEventServiceService  ) {

    this.getAllWeddingParties();

  }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("View All Wedding Parties");

  }


  getAllWeddingParties() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/wps', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.Loading = false;

      console.log("Response:" + JSON.stringify(data));

      this.allWeddingParties = data;

    },
      (error) => {

        this.Loading = false;

        //console.error('Error' + error);

      }
    );


  }

  createWeddingParty() {

    const initialState = {
      memberData: [
      ]
    };

    this.newWeddingPartyModal = this.modalService.show(CreateWeddingPartyComponent,{ initialState, class: 'modal-md' });

    this.newWeddingPartyModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllWeddingParties();

      }
    });


  }


  viewWeddingParty(weddignParty) {

    this.router.navigate(['/viewWeddingParty/'], weddignParty.id);

  }

}
