import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { tr } from 'date-fns/locale';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-preferred-vendor',
  templateUrl: './create-preferred-vendor.component.html',
  styleUrls: ['./create-preferred-vendor.component.scss']
})
export class CreatePreferredVendorComponent implements OnInit {


  token: any;
  reqHeader: any;
  postData: any;

  searchText: any = "";

  Loading: Boolean = false;

  weddingPartId: any;
  weddignPartyList : Boolean = false;

  isnewPreferredVendor: boolean = true;
  isInvitedVendor: boolean = false;

  vendorLogo: any;
  companyName: any;
  email: any;
  phone: any;

  preferredVendorID: any;

  currentPreferredVendorData: any;

  editParticipants: Boolean = false;

  totalVendorsCount: any = 0;
  allVendors: any = [];

  preferredVendorsList: any = [];

  preferredVendorData: any[] = [];
  memberData: any[] = [];

  showNewSection: Boolean = false;

  public event: EventEmitter<any> = new EventEmitter();

  userData: any;

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private mixpanelService: MixpanelEventServiceService
  ) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = JSON.parse(localStorage.getItem('user'));

  }

  ngOnInit(): void {

    if (this.preferredVendorData[0]) {

      this.currentPreferredVendorData = this.preferredVendorData[0];

      this.isnewPreferredVendor = false;

      if (this.currentPreferredVendorData.invited == true) {

        this.isInvitedVendor = true;

        this.companyName = decodeURI(this.preferredVendorData[0].vvendorinvite.companyname);
        this.email = this.preferredVendorData[0].vvendorinvite.email;
        this.phone = this.preferredVendorData[0].vvendorinvite.phone;


      } else if (this.currentPreferredVendorData.invited == false) {

        this.isInvitedVendor = false;

        if (this.currentPreferredVendorData.vvendor != null) {

          if(this.currentPreferredVendorData.vvendor.logo && this.currentPreferredVendorData.vvendor.logo.length > 0){
            
            this.vendorLogo = this.currentPreferredVendorData.vvendor.logo[0].url;

          }

          this.companyName = this.currentPreferredVendorData.vvendor.name;
          this.email = this.currentPreferredVendorData.vvendor.email;
          this.phone = this.currentPreferredVendorData.vvendor.phone;

        }


      }


      if (this.preferredVendorData[2]) {

        this.weddingPartId = this.preferredVendorData[2];
        this.weddignPartyList = true;

      }


    } else if (this.memberData[0]) {

      if (this.memberData[1]) {

        this.weddingPartId = this.memberData[1];
        this.weddignPartyList = true;

      }

    }

    if (this.isnewPreferredVendor) {

      if(this.weddignPartyList){

        this.getAllPreferredVendors();

      }else{

        this.getAllVendors();

      }

    }


  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  hidePreferredVendorModel(): void {

    this.bsModalRef.hide();
  }

  toggleNewSection(){

    this.showNewSection = !this.showNewSection;
  }

  getAllPreferredVendors() {

    this.http.get(environment.strapiUrl + '/preferredvendorlists?tag=' + this.userData.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.preferredVendorsList = data;

    });

  }

  updateSearchVendorList(event: any){

    this.getAllVendors();
    
  }

  getAllVendors() {

    this.Loading = true;

    this.http.get(environment.strapiUrl + '/vvendors?name_contains=' + this.searchText, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));


      this.allVendors = data.vendors;
      this.totalVendorsCount = data.total;

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

      });

  }

  addNewPreferredVendor(invite: boolean, Vendor) {

    if (invite) {

      if(Vendor != null){

        this.companyName = Vendor.vvendorinvite.companyname;
        this.email = Vendor.vvendorinvite.email;
        this.phone = Vendor.vvendorinvite.phone;

      }

      if (this.companyName && this.email && this.phone) {


        let postData = {
          companyname: this.companyName,
          email: this.email,
          phone: this.phone,
        };

        if (this.weddingPartId != null) {

          postData['tag'] = "" + this.weddingPartId;

        } else {

          postData['tag'] = "" + this.userData.id;

        }

        this.createNewPreferredVendor(postData);

      } else {

        this.toasstService.showNotification('danger', "Error", "Add a All Details!");

      }

    } else if (invite == false && Vendor != null && Vendor.id) {

      let postData = {
        vvendor: Vendor.id
      };

      if (this.weddingPartId != null) {

        postData['tag'] = "" + this.weddingPartId;

      } else {

        postData['tag'] = "" + this.userData.id;

      }

      //console.log("User Data:" + JSON.stringify(this.userData));
      //console.log("Post Data Vendor:" + JSON.stringify(postData));

      this.createNewPreferredVendor(postData);

    }
  }

  createNewPreferredVendor(postData) {



    this.http.post(environment.strapiUrl + '/preferredvendorlists', postData, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      //console.log("Response:" + JSON.stringify(data));


      this.toasstService.showNotification('success', "Success", "Vendor Added successfully!");
      this.triggerEvent("true");

      this.mixpanelService.trackEvent("Preferred Vendor Added");

      this.bsModalRef.hide();


    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

  updatePreferredVendor() {

    if (this.companyName && this.email && this.phone) {


      let postData = {
        companyname: encodeURI(this.companyName),
        email: this.email,
        phone: this.phone,
        tag: this.weddingPartId
      };

      this.http.put(environment.strapiUrl + '/preferredvendorlists/' + this.currentPreferredVendorData.id, postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        //console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Vendor updated successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a All Details!");

    }

  }

  deletePreferredVendor() {

    this.http.delete(environment.strapiUrl + '/preferredvendorlists/' + this.currentPreferredVendorData.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      //console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('danger', "Success", "Vendor Deleted successfully!");

      this.triggerEvent("true");

      this.mixpanelService.trackEvent("Preferred Vendor Deleted");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

}
