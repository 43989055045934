import {
  Component,
  OnInit,
  TemplateRef,
  ElementRef,
  ViewChild
} from "@angular/core";
import { tr } from "date-fns/locale";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  disabled: boolean = true;

  noteModal: BsModalRef;
  editModal: BsModalRef;
  @ViewChild("modalNote") modalNote: ElementRef;

  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop : true,
    ignoreBackdropClick: true,
    animated: true
  };

  isnewNote: boolean = false;
  iseditNote: boolean = false;

  title?:any;
  description?:any;
  date?:any;
  noteID:any;

  currentmodelNoteData?:any;
  
  notesData = [
    {
      noteId: "1",
      title : "Book Photographer",
      description:"Lorem Ipsum",
      date:"Tue Feb 09 2021 19:14:29 GMT-0500 (EST)",
      personalNote: true,
      participants: [
        {
          user_id:"8160",
          user_name:"Harshit Pethani",
          profile_pic:"2020-12-08_836_profileimage.png",
          user_email:"pethaniharshit@gmail.com",
          user_phoneno:"+12269894412",
          user_gender:""
        },
        {
          user_id:"8560",
          user_name:"Hejan p",
          profile_pic:"2020-06-07_333_profileimage.png",
          user_email:"Hejanpatel2343@gmail.com",
          user_phoneno:"+919574284218",
          user_gender:""
        }
      ]
    },
    {
      noteId: "2",
      title : "Write Weeding Night Speech",
      description:"lorem ipsum",
      date:"Tue Feb 09 2021 19:14:29 GMT-0500 (EST)",
      personalNote: true,
      participants: [
        {
          user_id:"8160",
          user_name:"Harshit Pethani",
          profile_pic:"2020-12-08_836_profileimage.png",
          user_email:"pethaniharshit@gmail.com",
          user_phoneno:"+12269894412",
          user_gender:""
        }
      ]
    },

  ];

  constructor(public modalService: BsModalService) { }

  ngOnInit(): void {

  }

  numSequence(n: number): Array<number> { 
    return Array(n); 
  } 

  addNewNote() : void {

    this.isnewNote = true;
    this.iseditNote = false;

    this.noteModal = this.modalService.show(this.modalNote, this.default);

  }

  viewNote(noteDetails): void{

    this.isnewNote = false;
    this.iseditNote = false;

    this.title = noteDetails.title;
    this.description = noteDetails.description;
    this.date = noteDetails.date;

    this.noteModal = this.modalService.show(this.modalNote, this.default);

  }

  editNote(noteDetails): void{

    this.isnewNote = false;
    this.iseditNote = true;


    this.title = noteDetails.title;
    this.description = noteDetails.description;
    this.date = noteDetails.date;
    this.noteID = noteDetails.noteId;

    this.noteModal = this.modalService.show(this.modalNote, this.default);

  }

  hideNoteModel():void{

    this.noteModal.hide();


  }

  addNewNoteData():void{

    let notedata = {

      noteId: "" + this.notesData.length + 1,
      title : this.title,
      description: this.description,
      date: this.date,
      personalNote: true,
      participants: [
        {
          user_id:"8160",
          user_name:"Harshit Pethani",
          profile_pic:"2020-12-08_836_profileimage.png",
          user_email:"pethaniharshit@gmail.com",
          user_phoneno:"+12269894412",
          user_gender:""
        }
      ]

    };

    //console.log(notedata);

    this.notesData.push(notedata);

    this.noteModal.hide();

  }

  updateNoteData():void {

    console.log("Current Note ID: " + this.noteID);
    
    for(let i=0; i<this.notesData.length ; i++){

      console.log("Out" + this.notesData[i].noteId);

      if(this.notesData[i].noteId == this.noteID){

        console.log(this.notesData[i].noteId);

        this.notesData[i].title = this.title;
        this.notesData[i].date = this.date;
        this.notesData[i].description = this.description;

      }

    }

    this.noteModal.hide();

  }



}
