import { Component, OnInit } from '@angular/core';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { EventServiceService } from 'src/app/services/event-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddListingComponent } from '../add-listing/add-listing.component';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss']
})
export class ListingsComponent implements OnInit {

  Loading: Boolean = false;

  viewListingModal: BsModalRef;
  createListingModal: BsModalRef;

  allListings: any = [];
  activeListing: any = [];
  inactiveListing: any = [];

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private mixpanelService: MixpanelEventServiceService,
    private modalService: BsModalService) {


    this.getAllUSerListings();

  }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("Listings");

  }

  decodeText(data){

    return decodeURI(data);

  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  getMediaUrl(mediaArray) {

    let mediaReverse = mediaArray.reverse();
    return mediaReverse[0].url;

  }

  getAllUSerListings() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vvendoroffers/me', { headers: reqHeader }).subscribe(response => {

      this.Loading = false;

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.activeListing = data.filter((offer) => {

        return (offer.isactive == true);

      });

      this.inactiveListing = data.filter((offer) => {

        return (offer.isactive == false);

      });

      console.log("Response:" + JSON.stringify(data));

      this.allListings = data;

    },
      (error) => {

        this.Loading = false;
        console.error('Error' + error);

      });


  }

  viewListing(listing) {

    const initialState = {
      listingData: [
        listing
      ]
    };

    this.viewListingModal = this.modalService.show(AddListingComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    this.viewListingModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllUSerListings();

      }
    });


  }

  createListing() {

    const initialState = {
      memberData: [
      ]
    };

    this.createListingModal = this.modalService.show(AddListingComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    this.createListingModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllUSerListings();

      }
    });

  }


}
