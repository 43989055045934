import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wedding-planner-landing',
  templateUrl: './wedding-planner-landing.component.html',
  styleUrls: ['./wedding-planner-landing.component.scss']
})
export class WeddingPlannerLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
