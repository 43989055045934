import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { ViewAdviceAndTipsComponent } from '../view-advice-and-tips/view-advice-and-tips.component';

@Component({
  selector: 'app-advice-and-tips',
  templateUrl: './advice-and-tips.component.html',
  styleUrls: ['./advice-and-tips.component.scss']
})
export class AdviceAndTipsComponent implements OnInit {

  Loading: Boolean = false;

  allAdviceAndTips = [];

  viewAdviceAndTipsModal: BsModalRef;
  createAdviceAndTipsModal: BsModalRef;

  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private mixpanelService: MixpanelEventServiceService,
    private modalService: BsModalService
  ) {

    this.getAllAdviceAndTips();

   }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("View All Advice and Tips");
    
  }

  decodeText(data){

    return decodeURI(data);

  }

  getAllAdviceAndTips(){

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vadviceandtips', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.Loading = false;

      //console.log("Response:" + JSON.stringify(data));

      this.allAdviceAndTips = data;

    },
      (error) => {

        this.Loading = false;
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");
        //console.error('Error' + error);

      }
    );



  }

  viewAdviceAndTips(adviceAndTip) {

    const initialState = {
      adviceData: [
        adviceAndTip
      ]
    };

    this.viewAdviceAndTipsModal = this.modalService.show(ViewAdviceAndTipsComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    this.viewAdviceAndTipsModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllAdviceAndTips();

      }
    });


  }

  createAdviceAndTips() {

    const initialState = {
      memberData: [
      ]
    };

    this.createAdviceAndTipsModal = this.modalService.show(ViewAdviceAndTipsComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    this.createAdviceAndTipsModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllAdviceAndTips();

      }
    });

  }



}
