import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-planning-category',
  templateUrl: './add-planning-category.component.html',
  styleUrls: ['./add-planning-category.component.scss']
})
export class AddPlanningCategoryComponent implements OnInit {

  Loading: Boolean = false;

  token: any;
  reqHeader: any;
  postData: any;

  allCategories: any[] = [];
  parentCategories: any[] = [];
  selectedCategories: any[] = [];

  planningCategoryData: any = [];

  public event: EventEmitter<any> = new EventEmitter();
  
  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef) { }

  ngOnInit(): void {

    if (this.planningCategoryData[0]) {

      this.selectedCategories = this.planningCategoryData[0];

    }

    this.getAllCaregories();

  }

  hideModel(): void {

    this.bsModalRef.hide();

  }

  getAllCaregories() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vcategories', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      data.forEach(element => {

        element['selected'] = false;
        element['disable'] = false;

        this.selectedCategories.forEach(category => {

          if (category.id == element.id) {

            element['selected'] = true;
            element['disable'] = true;

          }

        })


      });


      this.parentCategories = data.filter((data) => {

        return (data.parent == null);

      });


      data = data.filter((data) => {

        return (data.parent != null);

      });



      this.allCategories = data;

      //console.log("All Categories:" + JSON.stringify(this.allCategories));

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

      });


  }



  addPlanningCategories() {

    let selectedCategories = [];

    this.allCategories.forEach(element => {

      if (element.selected == true && element.disable == false) {

        let category = {
          "vcategory": element,
          "isbooked": false,
          "vvendor": null,
          "budget": null
        }

        selectedCategories.push(category);
      }

    });

    this.parentCategories.forEach(element => {

      if (element.selected == true && element.disable == false) {

        let category = {
          "vcategory": element,
          "isbooked": false
        }

        selectedCategories.push(category);
      }

    });

    this.triggerEvent(JSON.stringify(selectedCategories));

    this.toasstService.showNotification('success', "Success", "Categories Added Successfully!");

    this.bsModalRef.hide();

  }

  triggerEvent(item: string) {

    this.event.emit({ data: item, res: 200 });

  }

}
