import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-chat-group',
  templateUrl: './create-chat-group.component.html',
  styleUrls: ['./create-chat-group.component.scss']
})
export class CreateChatGroupComponent implements OnInit {

  token: any;
  reqHeader: any;
  postData: any;

  weddingPartId: any;

  isnewChat: boolean = true;
  iseditChat: boolean = false;

  title: any;
  description: any;
  date: any;
  chatID: any;

  currentChatData: any;

  editParticipants: Boolean = false;

  selectedParticipants: any = [];
  allParticipants: any = [];
  allMembers: any = [];


  chatData: any[] = [];
  memberData: any[] = [];

  public event: EventEmitter<any> = new EventEmitter();

  userData: any;

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = localStorage.getItem('user');


  }

  ngOnInit(): void {

    if (this.chatData[0]) {

      this.currentChatData = this.chatData[0];

      this.isnewChat = false;

      this.title = decodeURI(this.chatData[0].title);

      if (this.chatData[1]) {

        this.allMembers = this.chatData[1];
        this.allParticipants = this.allMembers;

      }

      if (this.chatData[2]) {

        this.weddingPartId = this.chatData[2];

      }


    } else if (this.memberData[0]) {

      this.allMembers = this.memberData[0];
      this.allParticipants = this.allMembers;

      if (this.memberData[1]) {

        this.weddingPartId = this.memberData[1];

      }

    }

  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  hideNoteModel(): void {

    this.bsModalRef.hide();
  }

  toggleEditParticipants() {

    this.editParticipants = !this.editParticipants;

    for (let member of this.allMembers) {

      this.currentChatData.sharedwith.forEach(sharedMember => {

        if (member.id == sharedMember.member.id) {

          this.selectedParticipants.push(member);

        }

      });

      if (member.id == this.currentChatData.wpmember.id) {

        this.selectedParticipants.push(member);

      }

    }

    this.allParticipants = this.allMembers;


    this.selectedParticipants.forEach(selectedMember => {

      for (let i = 0; i < this.allParticipants.length; i++) {

        if (this.allParticipants[i].id == selectedMember.id) {

          this.allParticipants.splice(i, 1);
          continue;
        }

      }

    });

    //console.log("Selected Participants: " + JSON.stringify(this.selectedParticipants));
    //console.log("All Participants: " + JSON.stringify(this.allParticipants));

  }

  selectParticipant(member, index) {

    this.selectedParticipants.push(member);
    this.allParticipants.splice(index, 1);

  }

  removeParticipant(member, index) {

    this.allParticipants.push(member);
    this.selectedParticipants.splice(index, 1);

  }

  createChatGroup() {

    if (this.title && this.selectedParticipants.length > 1) {


      let postData = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
      };


      postData["shared"] = true;

      let sharedwith = [];

      for (let member of this.selectedParticipants) {

        if (!(member.user.id == this.userData.id)) {

          let obj = {

            "id": member.id,
            "isadmin": true

          };

          sharedwith.push(obj);

        }

      }

      postData["sharedwith"] = sharedwith;


      this.http.post(environment.strapiUrl + '/wpchats', postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Chat Group Created Successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Description!");

    }

  }

  updateChatGroup() {

    if (this.title && this.description) {


      let postData = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
      };


      if (this.editParticipants) {

          postData["shared"] = true;

          let sharedwith = [];

          for (let member of this.selectedParticipants) {

            if (!(member.user.id == this.userData.id)) {

              let obj = {

                "id": member.id,
                "isadmin": true

              };

              sharedwith.push(obj);

            }

          }

          postData["sharedwith"] = sharedwith;

      } else {

          postData["shared"] = this.currentChatData.shared;
          postData["sharedwith"] = this.currentChatData.sharedwith;

      }



      this.http.put(environment.strapiUrl + '/wpnotes/' + this.currentChatData.id, postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Note Updated successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Description!");

    }

  }


}
