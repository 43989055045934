import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { EventDetailsComponent } from '../event-details/event-details.component';
import { TaskDetailsComponent } from '../task-details/task-details.component';

@Component({
  selector: 'app-event-planning-details',
  templateUrl: './event-planning-details.component.html',
  styleUrls: ['./event-planning-details.component.scss']
})
export class EventPlanningDetailsComponent implements OnInit {

  Loading: Boolean = false;

  currentTab = "Planning";

  allEventTasks: any = [];
  taskModal: BsModalRef;

  eventModal: BsModalRef;

  token: any;
  reqHeader: any;
  postData: any;

  weddingPartId: any;

  isnewEvent: boolean = true;
  iseditEvent: boolean = false;

  title: any;
  location: any;
  eventStartDate: any;
  eventStartTime: any;
  eventEndDate: any;
  eventEndTime: any;
  description: any;
  eventBudget: any;
  eventID: any;

  budgetData: any;

  showPlanningProgress: Boolean = true;
  personalEvent: Boolean = false;

  currentEventData: any;

  editParticipants: Boolean = false;

  selectedParticipants: any = [];
  allParticipants: any = [];
  allMembers: any = [];


  eventData: any[] = [];
  memberData: any[] = [];

  public event: EventEmitter<any> = new EventEmitter();

  userData: any;

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = localStorage.getItem('user');



  }

  ngOnInit(): void {

    if (this.eventData[0]) {

      this.currentEventData = this.eventData[0];

      this.isnewEvent = false;

      this.title = decodeURI(this.eventData[0].title);

      if (this.currentEventData.location != null) {

        this.location = this.currentEventData.location;

      }

      this.eventStartDate = this.currentEventData.startdate;

      if (this.currentEventData.enddate && this.currentEventData.enddate != null) {

        this.eventEndDate = this.currentEventData.enddate;

      }

      if (this.currentEventData.starttime && this.currentEventData.starttime != null) {

        this.eventStartTime = this.currentEventData.starttime.substring(0, 5);;

      }

      if (this.currentEventData.endtime && this.currentEventData.endtime != null) {

        this.eventEndTime = this.currentEventData.endtime.substring(0, 5);;

      }

      if (this.currentEventData.description != null && this.currentEventData.description != '' && this.currentEventData.description != 'null') {

        this.description = decodeURI(this.currentEventData.description);

      }

      this.personalEvent = !this.currentEventData.shared;

      if (this.currentEventData.budget && this.currentEventData.budget != null) {


        this.budgetData = this.currentEventData.budget;

        if (this.budgetData && this.budgetData.totalbudget) {

          this.eventBudget = this.budgetData.totalbudget;

        }
        else {

          this.eventBudget = 0;

        }

      }

      if (this.eventData[1]) {

        this.allMembers = this.eventData[1];
        this.allParticipants = this.allMembers;

      }

      if (this.eventData[2]) {

        this.weddingPartId = this.eventData[2];

      }


    } else if (this.memberData[0]) {

      this.allMembers = this.memberData[0];
      this.allParticipants = this.allMembers;
      this.personalEvent = false;

      if (this.memberData[1]) {

        this.weddingPartId = this.memberData[1];

      }

    }


  }


  hideEventModel(): void {

    this.bsModalRef.hide();
  }

  changeTab(tabName) {

    this.currentTab = tabName;



    switch (this.currentTab) {

      case "Planning": {



      }

      case "Notes": {


      }

      case "Tasks": {

        this.getAllEventTasks();

      }
    }



  }

  getDecodeURIData(string: any) {

    return decodeURI(string);

  }

  getTime(time: any) {

    if (time != null) {

      let hour = (time.split(':'))[0]
      let min = (time.split(':'))[1]
      let part = hour >= 12 ? 'PM' : 'AM';
      min = (min + '').length == 1 ? `0${min}` : min;
      hour = hour > 12 ? hour - 12 : hour;
      hour = (hour + '').length == 1 ? `0${hour}` : hour;

      return `${hour}:${min} ${part}`

    } else {

      return "";

    }

  }

  getAllEventTasks() {

    this.http.get(environment.strapiUrl + '/wptasks?wp=' + this.weddingPartId + '&event=' + this.currentEventData.id, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.allEventTasks = data;

    });

  }


  //Edit Event
  editEvent(): void {


    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      eventData: [
        this.currentEventData,
        memberList,
        this.weddingPartId
      ]
    };


    this.eventModal = this.modalService.show(EventDetailsComponent, { initialState });


    this.eventModal.content.event.subscribe(res => {
      if (res.data == "true") {

        //this.getAllEvents();

      }
    });

  }

  ChangeTaskStatus(taskDetails) {

    let postData = {
      wp: this.weddingPartId,
      completed: !taskDetails.completed,
      taskId: taskDetails.id
    };


    this.http.put(environment.strapiUrl + '/wptasks/' + taskDetails.id, postData, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('success', "Success", "Task Updated successfully!");

      this.getAllEventTasks();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );


  }

  addNewTask(): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      memberData: [
        memberList,
        this.weddingPartId
      ]
    };

    this.taskModal = this.modalService.show(TaskDetailsComponent, { initialState });

    this.taskModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllEventTasks();

      }
    });

  }

  editTask(taskDetails): void {

    let memberList = this.allMembers.filter((member) => {

      return (member.user != null);

    });

    const initialState = {
      taskData: [
        taskDetails,
        memberList,
        this.weddingPartId
      ]
    };

    this.taskModal = this.modalService.show(TaskDetailsComponent, { initialState });

    this.taskModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllEventTasks();

      }
    });

  }

}
