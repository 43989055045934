import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { th, tr } from 'date-fns/locale';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit {

  token: any;
  reqHeader: any;
  postData: any;

  weddingPartId: any;

  isnewTask: boolean = true;

  title: any;
  description: any;
  dueDate: any;
  personalTask: Boolean = false;
  editParticipants: Boolean = false;

  currentTaskData: any;
  taskParticipants: any = [];
  selectedParticipants: any = [];
  allParticipants: any = [];
  allMembers: any = [];

  userData: any;

  taskData: any[] = [];
  memberData: any[] = [];

  isEventTask: Boolean = false;
  eventId: any;

  public event: EventEmitter<any> = new EventEmitter();

  deleteModal: BsModalRef;
  @ViewChild("modalDelete") modalDelete: ElementRef;
  
  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true
  };


  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = localStorage.getItem('user');

  }

  ngOnInit(): void {

    if (this.taskData[0]) {

      this.currentTaskData = this.taskData[0];

      this.isnewTask = false;

      this.title = decodeURI(this.taskData[0].title);
      this.description = decodeURI(this.taskData[0].description);
      if (this.taskData[0].duedate != null) {
        this.dueDate = this.taskData[0].duedate;
      }

      this.personalTask = !this.taskData[0].shared;

      if (this.taskData[1]) {

        this.allMembers = this.taskData[1];
        this.allParticipants = this.allMembers;

      }

      if(this.taskData[2]){

        this.weddingPartId = this.taskData[2];
        
      }

      if(this.taskData[3]){

        this.isEventTask = true;
        this.eventId = this.taskData[3];
        
      }


    } else if (this.memberData[0]) {

      this.allMembers = this.memberData[0];
      this.allParticipants = this.allMembers;
      this.personalTask = false;

      if(this.memberData[1]){

        this.weddingPartId = this.memberData[1];
        
      }

      if(this.memberData[2]){

        this.isEventTask = true;
        this.eventId = this.memberData[2];
        
      }

    }

  }


  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  hideModel(): void {

    this.bsModalRef.hide();
  }

  toggleEditParticipants() {

    this.editParticipants = !this.editParticipants;

    for (let member of this.allMembers) {

      this.currentTaskData.sharedwith.forEach(sharedMember => {

        if (member.id == sharedMember.member.id) {

          this.selectedParticipants.push(member);

        }

      });

      if (member.id == this.currentTaskData.wpmember.id) {

        this.selectedParticipants.push(member);

      }

    }

    this.allParticipants = this.allMembers;


    this.selectedParticipants.forEach(selectedMember => {

      for (let i = 0; i < this.allParticipants.length; i++) {

        if (this.allParticipants[i].id == selectedMember.id) {

          this.allParticipants.splice(i, 1);
          continue;
        }

      }

    });


    console.log("Selected Participants: " + JSON.stringify(this.selectedParticipants));

    console.log("All Participants: " + JSON.stringify(this.allParticipants));
  }

  selectParticipant(member, index) {

    this.selectedParticipants.push(member);
    this.allParticipants.splice(index, 1);

  }

  removeParticipant(member, index) {

    this.allParticipants.push(member);
    this.selectedParticipants.splice(index, 1);

  }

  createNewTask() {

    if (this.title && this.description) {


      let postData = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
      };


      if (this.personalTask) {

        postData["shared"] = false;
        postData["sharedwith"] = [];

      } else if (this.selectedParticipants.length > 1) {

        postData["shared"] = true;

        let sharedwith = [];

        for (let member of this.selectedParticipants) {

          if (!(member.user.id == this.userData.id)) {

            let obj = {

              "id": member.id,
              "isadmin": true

            };

            sharedwith.push(obj);

          }

        }

        postData["sharedwith"] = sharedwith;

      } else {

        postData["shared"] = false;
        postData["sharedwith"] = [];

      }

      if(this.isEventTask){

        postData["wpevent"] = this.eventId;

      }

      console.log("Add task para:" + JSON.stringify(postData));


      this.http.post(environment.strapiUrl + '/wptasks', postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Task Added successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Description!");

    }

  }

  updateTask() {

    if (this.title && this.description) {


      let postData = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
      };

      if (this.editParticipants) {

        if (this.personalTask) {

          postData["shared"] = false;
          postData["sharedwith"] = [];

        } else if (this.selectedParticipants.length > 1) {

          postData["shared"] = true;

          let sharedwith = [];

          for (let member of this.selectedParticipants) {

            if (!(member.user.id == this.userData.id)) {

              let obj = {

                "id": member.id,
                "isadmin": true

              };

              sharedwith.push(obj);

            }

          }

          postData["sharedwith"] = sharedwith;

        } else {

          postData["shared"] = false;
          postData["sharedwith"] = [];

        }


      } else {

        postData["shared"] = this.currentTaskData.shared;
        postData["sharedwith"] = this.currentTaskData.sharedwith;

      }

      if(this.isEventTask){

        postData["wpevent"] = this.eventId;

      }

      console.log("Update note para:" + JSON.stringify(postData));


      this.http.put(environment.strapiUrl + '/wptasks/' + this.currentTaskData.id, postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Task Updated successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Description!");

    }

  }

  deleteModel() {

    this.deleteModal = this.modalService.show(this.modalDelete, this.default);

  }

  hideDeleteModel(): void {

    this.deleteModal.hide();

  }

  deleteTask() {

    this.deleteModal.hide();
    
    this.http.delete(environment.strapiUrl + '/wptasks/' + this.currentTaskData.id + "?wp=" + this.weddingPartId, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('danger', "Success", "Task Deleted successfully!");

      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

}
