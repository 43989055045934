import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { th, tr } from 'date-fns/locale';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-note-details',
  templateUrl: './note-details.component.html',
  styleUrls: ['./note-details.component.scss']
})
export class NoteDetailsComponent implements OnInit {

  token: any;
  reqHeader: any;
  postData: any;

  weddingPartId: any;

  isnewNote: boolean = true;
  iseditNote: boolean = false;

  title: any;
  description: any;
  date: any;
  noteID: any;
  personalNote: Boolean = false;

  currentNoteData: any;

  editParticipants: Boolean = false;

  selectedParticipants: any = [];
  allParticipants: any = [];
  allMembers: any = [];


  noteData: any[] = [];
  memberData: any[] = [];

  public event: EventEmitter<any> = new EventEmitter();

  userData: any;

  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {

    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = localStorage.getItem('user');


  }

  ngOnInit(): void {

    if (this.noteData[0]) {

      this.currentNoteData = this.noteData[0];

      this.isnewNote = false;

      this.title = decodeURI(this.noteData[0].title);
      this.description = decodeURI(this.noteData[0].description);
      this.personalNote = !this.noteData[0].shared;

      this.personalNote = !this.noteData[0].shared;

      if (this.noteData[1]) {

        this.allMembers = this.noteData[1];
        this.allParticipants = this.allMembers;

      }

      if (this.noteData[2]) {

        this.weddingPartId = this.noteData[2];

      }


    } else if (this.memberData[0]) {

      this.allMembers = this.memberData[0];
      this.allParticipants = this.allMembers;
      this.personalNote = false;

      if (this.memberData[1]) {

        this.weddingPartId = this.memberData[1];

      }

    }

  }

  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }

  hideNoteModel(): void {

    this.bsModalRef.hide();
  }

  toggleEditParticipants() {

    this.editParticipants = !this.editParticipants;

    for (let member of this.allMembers) {

      this.currentNoteData.sharedwith.forEach(sharedMember => {

        if (member.id == sharedMember.member.id) {

          this.selectedParticipants.push(member);

        }

      });

      if (member.id == this.currentNoteData.wpmember.id) {

        this.selectedParticipants.push(member);

      }

    }

    this.allParticipants = this.allMembers;


    this.selectedParticipants.forEach(selectedMember => {

      for (let i = 0; i < this.allParticipants.length; i++) {

        if (this.allParticipants[i].id == selectedMember.id) {

          this.allParticipants.splice(i, 1);
          continue;
        }

      }

    });

    //console.log("Selected Participants: " + JSON.stringify(this.selectedParticipants));
    //console.log("All Participants: " + JSON.stringify(this.allParticipants));

  }

  selectParticipant(member, index) {

    this.selectedParticipants.push(member);
    this.allParticipants.splice(index, 1);

  }

  removeParticipant(member, index) {

    this.allParticipants.push(member);
    this.selectedParticipants.splice(index, 1);

  }

  createNewNote() {

    if (this.title && this.description) {


      let postData = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
      };


      if (this.personalNote) {

        postData["shared"] = false;
        postData["sharedwith"] = [];

      } else if (this.selectedParticipants.length > 1) {

        postData["shared"] = true;

        let sharedwith = [];

        for (let member of this.selectedParticipants) {

          if (!(member.user.id == this.userData.id)) {

            let obj = {

              "id": member.id,
              "isadmin": true

            };

            sharedwith.push(obj);

          }

        }

        postData["sharedwith"] = sharedwith;

      } else {

        postData["shared"] = false;
        postData["sharedwith"] = [];

      }


      console.log("Add note para:" + JSON.stringify(postData));


      this.http.post(environment.strapiUrl + '/wpnotes', postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Note Added successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Description!");

    }

  }

  updateNote() {

    if (this.title && this.description) {


      let postData = {
        wp: this.weddingPartId,
        title: encodeURI(this.title),
        description: encodeURI(this.description),
      };


      if (this.editParticipants) {

        if (this.personalNote) {

          postData["shared"] = false;
          postData["sharedwith"] = [];

        } else if (this.selectedParticipants.length > 1) {

          postData["shared"] = true;

          let sharedwith = [];

          for (let member of this.selectedParticipants) {

            if (!(member.user.id == this.userData.id)) {

              let obj = {

                "id": member.id,
                "isadmin": true

              };

              sharedwith.push(obj);

            }

          }

          postData["sharedwith"] = sharedwith;

        } else {

          postData["shared"] = false;
          postData["sharedwith"] = [];

        }


      } else {

        if (this.personalNote) {

          postData["shared"] = false;
          postData["sharedwith"] = [];

        } else {

          postData["shared"] = this.currentNoteData.shared;
          postData["sharedwith"] = this.currentNoteData.sharedwith;

        }

      }



      this.http.put(environment.strapiUrl + '/wpnotes/' + this.currentNoteData.id, postData, { headers: this.reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        console.log("Response:" + JSON.stringify(data));

        this.toasstService.showNotification('success', "Success", "Note Updated successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();


      },
        (error) => {

          console.error('Error' + error);
          this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

        }

      );


    } else {

      this.toasstService.showNotification('danger', "Error", "Add a Title & Description!");

    }

  }


  deleteNote() {

    this.http.delete(environment.strapiUrl + '/wpnotes/' + this.currentNoteData.id + "?wp=" + this.weddingPartId, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.toasstService.showNotification('danger', "Success", "Note Deleted successfully!");

      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );

  }

}
