import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { da } from 'date-fns/locale';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat-details',
  templateUrl: './chat-details.component.html',
  styleUrls: ['./chat-details.component.scss']
})
export class ChatDetailsComponent implements OnInit {

  @ViewChild('chatScroll') private myScrollContainer: ElementRef;

  Loading: Boolean = false;

  token: any;
  reqHeader: any;
  postData: any;

  allMembers: any = [];

  userData: any;

  chatData: any[] = [];
  memberData: any[] = [];

  weddingPartyId: any;
  currentMember: any;

  messages: any = [];

  chatId: any;
  chatTitle: any = "Chat Group";
  chatGroup: any;

  startindex: any = 0;
  limit: any = 15;

  imgFile: string;
  imgSrc: any;
  selectedFileName: string;
  selectedFile: File;

  newMessage: any;
  ngZone: any;
  postMessageParams: {};
  userlocalMessage: any;
  messageInputText: any;

  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {
    this.token = localStorage.getItem('jwt');

    this.reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.userData = JSON.parse(localStorage.getItem('user'));
    //this.currentMember = localStorage.getItem('member');
  }

  ngOnInit(): void {

    if (this.chatData[0]) {

      this.chatGroup = this.chatData[0];

      this.chatId = this.chatData[0].id;
      this.chatTitle = decodeURI(this.chatData[0].title);

      if (this.chatData[1]) {

        this.allMembers = this.chatData[1];


        for (let member of this.allMembers) {

          if (member.user && member.user.id == this.userData.id) {

            this.currentMember = member;
            break;
          }

        }

      }

      if (this.chatData[2]) {

        this.weddingPartyId = this.chatData[2];

      }


    } else if (this.memberData[0]) {

      this.allMembers = this.memberData[0];
    }


    this.getChatGroupMessages(true);

  }

  hideChatModel(): void {

    this.bsModalRef.hide();
  }


  getDecodeURIData(string: any) {

    return decodeURI(string);

  }

  iscurrentuser(message) {

    if (message.wpmember.id) {

      if (message.wpmember.id == this.currentMember.id) {
        return true;
      } else {
        return false;
      }

    }
    else if (message.wpmember && message.wpmember == this.currentMember.id) {

      return true;

    } else {

      return false;

    }


  }

  getChatGroupMessages(init: boolean) {

    if (init) {
      this.startindex = 0;
      this.messages = [];

    }

    let postParams = {
      chatId: this.chatId,
      wp: this.weddingPartyId,
      start: this.startindex,
      limit: this.limit
    };

    console.log("Chat Group Messages Post Data:" + JSON.stringify(postParams));

    this.http.get(environment.strapiUrl + '/wpchatmessages?wp=' + this.weddingPartyId + '&wpchat=' + this.chatId + '&_sort=id:DESC' + '&_start=' + postParams.start + '&_limit=' + postParams.limit, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      data = data.filter((message) => {

        return (message.deleted == false);

      });

      data.forEach(msg => {

        this.messages.push(msg);

      });

      //this.messages = data;

      //this.messages.reverse();

      this.scrollToBottom();
    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Error in getting chat messages, please try after some time!");

      }

    );


  }

  replaceURLWithHTMLLinks(text) {
    var exp = '/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig';
    return text.replace(exp, "<a href='$1'>$1</a>");
  }

  sendMessage() {

    if (this.selectedFile) {

      let postParams = {

        wpchat: this.chatId,
        wp: this.weddingPartyId,
        message: encodeURI(this.newMessage),
        type: "file"

      };

      this.sendMessagetoServer(postParams);

    } else {

      if (this.newMessage) {

        let Message = {
          "message": encodeURI(this.newMessage),
          "deleted": false,
          "type": "text",
          "wpmember": this.currentMember
        }

        console.log("New Message" + JSON.stringify(Message));

        this.messages.reverse();
        this.messages.push(Message);
        this.messages.reverse();

        let messageText = this.newMessage;
        this.newMessage = '';

        let postParams = {

          wpchat: this.chatId,
          wp: this.weddingPartyId,
          message: encodeURI(messageText),
          type: "text"

        };

        this.sendMessagetoServer(postParams);

      }

    }
  }


  //sends message to serve
  sendMessagetoServer(postParams) {

    this.http.post(environment.strapiUrl + '/wpchatmessages', postParams, { headers: this.reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      if (data == "error") {

        this.toasstService.showNotification('danger', "Error", "Error in sending chat message!");

      } else if (data != null && data.id) {

        //this.messages.push(data);
        //this.scrollToBottom();

        if (this.selectedFile) {

          this.uploadImage(data.id);

        }

      }

    },
      (error) => {

        console.error('Error' + error);
        this.toasstService.showNotification('danger', "Error", "Error in getting chat messages, please try after some time!");

      }

    );
  }



  //add new message
  addNewMessage(msg) {

    console.log("message is " + JSON.stringify(msg));

    this.messages.splice(0, 0, msg);

    this.scrollToBottom();

  }

  scrollToBottom() {

    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }

  }


  onImageChange(e) {

    this.imgFile = "";
    this.imgSrc = "";
    this.selectedFileName = "";

    this.selectedFile = e.target.files[0];

    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;

      console.log("File Name :" + JSON.stringify(e.target.files[0].name));

      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imgFile = reader.result as string;
        this.imgSrc = reader.result;
        this.selectedFileName = e.target.files[0].name;

        this.newMessage = this.selectedFileName;

        console.log("Image File:" + this.imgFile);
        console.log("Image Src:" + this.imgSrc);

      };

    }
  }

  removeImage() {

    this.imgFile = "";
    this.imgSrc = "";
    this.selectedFileName = "";
    this.selectedFile = null;

    this.newMessage = null;

  }

  uploadImage(messageID) {

    this.Loading = true;

    let imagePostData = {

      messageId: messageID,
      wpchat: this.chatId,
      wp: this.weddingPartyId

    };

    const ext = this.selectedFileName.substr(this.selectedFileName.lastIndexOf('.') + 1);
    const mimetype = this.getMimeType(ext);

    //let blobData = this.b64toBlob(this.imgFile, mimetype.type);

    const formData = new FormData();

    let data = {
      "name": "name"
    };

    formData.append('files.Media', this.selectedFile, this.selectedFileName);
    formData.append('data', JSON.stringify(data));


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.put(environment.strapiUrl + '/wpchatmessages/uploadmedia/' + imagePostData.messageId + '?wp=' + imagePostData.wp + '&wpchat=' + imagePostData.wpchat, formData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      data.wpmember = this.currentMember;

      this.messages.reverse();
      this.messages.push(data);
      this.messages.reverse();


      this.imgFile = "";
      this.imgSrc = "";
      this.selectedFileName = "";
      this.selectedFile = null;

      this.newMessage = '';

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

        console.error('Error' + error);

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in image upload, Please try again!");

      }
    );


  }

  getMimeType(fileExt) {

    fileExt = fileExt.toLowerCase();

    switch (fileExt) {
      case 'wav': {
        return { type: `audio/wav` };
        break;
      }
      case 'pdf': {
        return { type: `application/pdf` };
        break;
      }
      case 'jpg': {
        return { type: `image/jpg` };
        break;
      }
      case 'png': {
        return { type: `image/png` };
        break;
      }
      case 'mp4': {
        return { type: `video/mp4` };
        break;
      }
      case 'mov': {
        return { type: `video/quicktime` };
        break;
      }
      case 'doc': {
        return { type: `application/msword` };
        break;
      }
      case 'docx': {
        return { type: `application/vnd.openxmlformats-officedocument.wordprocessingml.document` };
        break;
      }
      case 'ppt': {
        return { type: `application/vnd.ms-powerpoint` };
        break;
      }
      case 'pptx': {
        return { type: `application/vnd.openxmlformats-officedocument.presentationml.presentation` };
        break;
      }
      case 'xls': {
        return { type: `application/vnd.ms-excel` };
        break;
      }
      case 'xlsx': {
        return { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` };
        break;
      }
      case 'zip': {
        return { type: `application/zip` };
        break;
      }
      default: {
        return { type: `` };
        break;
      }
    }

    /*
     if (fileExt == 'wav') return { type: `audio/wav` };
     else if (fileExt == 'pdf') return { type: `application/pdf` };
     else if (fileExt == 'jpg') return { type: `image/jpg` };
     else if (fileExt == 'mp4') return { type: `video/mp4` };
     else if (fileExt == 'MOV') return { type: `video/quicktime` };
   */

  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadFile(url) {

    window.open(url, "_blank");

  }

}
