import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { RouterModule } from "@angular/router";
import { PresentationComponent } from "./presentation.component";
import { WeddingPlannerLandingComponent } from './wedding-planner-landing/wedding-planner-landing.component';
import { ManagementComponent } from './management/management.component';
import { MarketingComponent } from './marketing/marketing.component';
import { VendorsComponent } from './vendors/vendors.component';

@NgModule({
  declarations: [PresentationComponent, WeddingPlannerLandingComponent, ManagementComponent, MarketingComponent, VendorsComponent],
  imports: [CommonModule, RouterModule, TooltipModule.forRoot(), BsDropdownModule.forRoot(), CollapseModule.forRoot()]
})
export class PresentationModule {}
