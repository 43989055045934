import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-promotion',
  templateUrl: './view-promotion.component.html',
  styleUrls: ['./view-promotion.component.scss']
})
export class ViewPromotionComponent implements OnInit {

  Loading: Boolean = false;

  currentSection: any = "information";
  showNextSectionBt: Boolean = true;
  showPreviousSectionBt: Boolean = false;

  currentPromotionId: any;
  promotionData: any = [];

  selectedTerritories = [];
  selectedCategory: any;

  newPromotion: Boolean = true;
  currentPromotion: any;

  title: any;
  description: any;
  url: any;
  expiryDate: any;
  LocationSearch: any;

  activate: boolean = true;

  parentCategories: any = [];
  allCategories: any = [];
  allTerritories: any = [];

  imgFile: string;
  imgSrc: any;
  selectedFileName: string;
  selectedFile: File;

  public placeholder: string = 'Search Location';
  public keyword = 'name';
  public historyHeading: string = 'Recently selected';

  public event: EventEmitter<any> = new EventEmitter();

  deleteModal: BsModalRef;
  @ViewChild("modalDelete") modalDelete: ElementRef;

  default = {
    keyboard: true,
    class: "modal-dialog-centered modal-secondary",
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true
  };


  constructor(private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private mixpanelService: MixpanelEventServiceService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef) {

    //this.getAllTerritories()
    //this.getAllCaregories();

  }

  ngOnInit(): void {


    if (this.promotionData[0]) {

      this.currentPromotion = this.promotionData[0];
      //console.log("Current Offer:" + JSON.stringify(this.currentOffer));

      this.newPromotion = false;

      this.currentPromotionId = this.currentPromotion.id;

      this.title = this.currentPromotion.title;
      this.description = decodeURI(this.currentPromotion.description);
      this.url = this.currentPromotion.url;
      this.expiryDate = (this.currentPromotion.expirationdate.split('T'))[0];

      console.log("Expiry Date:" + JSON.stringify(this.expiryDate));

      this.activate = this.currentPromotion.published;

      //this.selectedCategory = this.currentPromotion.vcategory;
      // this.selectedTerritories = this.currentPromotion.vterritories;

      if (this.currentPromotion.image) {

        if (this.currentPromotion.image != null) {

          this.imgFile = this.currentPromotion.image.url;

        } else {

          this.imgFile = null;

        }


      }

    }


    if (this.newPromotion) {

      this.mixpanelService.trackEvent("Add New Promotion");

    } else {

      this.mixpanelService.trackEvent("View Promotion");

    }

  }

  hideModel(): void {

    this.bsModalRef.hide();
  }


  triggerEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }


  validateEmail(email) {

    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regularExpression.test(String(email).toLowerCase());

  }


  validateUrl(url) {

    const regularExpression2 = /https?:\/\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/;

    return regularExpression2.test(String(url).toLowerCase());

  }

  nextSection() {

    switch (this.currentSection) {

      case 'information': {

        this.currentSection = 'categories';
        this.showPreviousSectionBt = true;
        this.showNextSectionBt = false;

        break;
      }
      case 'categories': {
        break;
      }

    }

  }

  previousSection() {

    switch (this.currentSection) {

      case 'information': {
        break;
      }
      case 'categories': {

        this.currentSection = 'information';
        this.showNextSectionBt = true;
        this.showPreviousSectionBt = false;

        break;
      }

    }

  }

  selectSection(sectionName) {

    this.currentSection = sectionName;

    if (this.currentSection == 'information') {

      this.showNextSectionBt = true;
      this.showPreviousSectionBt = false;

    } else {

      this.showNextSectionBt = false;
      this.showPreviousSectionBt = true;

    }

  }

  AddNewLocation() {

    if (this.LocationSearch.name) {

      this.selectedTerritories.push(this.LocationSearch);
      this.LocationSearch = '';

    }

  }

  removeTerritory(index) {

    this.selectedTerritories.splice(index, 1);

  }

  getAllTerritories() {

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vterritories?name_contains=' + this.LocationSearch, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.allTerritories = data;

      console.log("All Territories:" + JSON.stringify(this.allCategories));

    },
      (error) => {

        this.Loading = false;

      });

  }

  getAllCaregories() {

    this.Loading = true;


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vcategories', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      data.forEach(element => {

        element['selected'] = false;

        if (this.selectedCategory && this.selectedCategory.id == element.id) {

          element['selected'] = true;

        }

      });


      this.parentCategories = data.filter((data) => {

        return (data.parent == null);

      });


      data = data.filter((data) => {

        return (data.parent != null);

      });



      this.allCategories = data;

      console.log("All Categories:" + JSON.stringify(this.allCategories));

      this.Loading = false;

    },
      (error) => {

        this.Loading = false;

      });


  }

  onImageChange(e) {

    this.imgFile = "";
    this.imgSrc = "";
    this.selectedFileName = "";

    this.selectedFile = e.target.files[0];

    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;

      console.log("File Name :" + JSON.stringify(e.target.files[0].name));

      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imgFile = reader.result as string;
        this.imgSrc = reader.result;
        this.selectedFileName = e.target.files[0].name;


        console.log("Image File:" + this.imgFile);
        console.log("Image Src:" + this.imgSrc);

      };

    }
  }

  saveChages() {

    if (this.title && this.description && this.url && this.expiryDate) {

        if (this.newPromotion) {

          this.createPromotion();

        } else {

          this.updatePromotion();

        }

    } else {

      this.toasstService.showNotification('warning', "Error", "Please enter all listing details!");

    }

  }

  uploadOfferImage(promotionId) {

    const ext = this.selectedFileName.substr(this.selectedFileName.lastIndexOf('.') + 1);

    const formData = new FormData();

    let data = {
      "name": "name"
    };

    formData.append('files.Media', this.selectedFile, this.selectedFileName);
    formData.append('data', JSON.stringify({}));


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.put(environment.strapiUrl + '/vpromotions/uploadmedia/' + promotionId, formData, { headers: reqHeader }).subscribe(response => {

      //this.imgFile = "";
      this.imgSrc = "";
      this.selectedFileName = "";
      this.selectedFile = null;

      this.Loading = false;

      if (this.newPromotion) {

        this.toasstService.showNotification('success', "Success", "New Promotion created successfully!");

      } else {

        this.toasstService.showNotification('success', "Success", "Promotion updated successfully!");

      }

      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + JSON.stringify(error.error));

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in image upload, Please try again!");

      }
    );


  }

  createPromotion() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let postData = {

      "title": this.title,
      "description": encodeURI(this.description),
      "isactive": this.activate,
      "url": this.url,
      "expirationdate": "" + this.expiryDate,
      "published": this.activate

    }

    /*  let selectedCategories = [];
  
      this.allCategories.forEach(element => {
  
        if (element.selected == true) {
          selectedCategories.push(element.id);
        }
  
      });
  
      this.parentCategories.forEach(element => {
  
        if (element.selected == true) {
          selectedCategories.push(element.id);
        }
  
      });
  
      postData["vcategory"] = selectedCategories;
  
  
      let selectedTerritories = [];
  
      this.selectedTerritories.forEach(element => {
  
        selectedTerritories.push(element.id);
  
      });
  
      postData["vterritories"] = selectedTerritories;
  */
    console.log("Post Data:" + JSON.stringify(postData));


    this.http.post(environment.strapiUrl + '/vpromotions', postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      if (this.selectedFileName) {

        this.uploadOfferImage(data.id);

      } else {

        this.Loading = false;

        this.toasstService.showNotification('success', "Success", "New Promotion created successfully!");

        this.triggerEvent("true");

        this.bsModalRef.hide();

      }



    },
      (error) => {

        this.Loading = false;

        console.error('Error' + JSON.stringify(error));
        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }

    );


  }

  updatePromotion() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let postData = {

      "title": this.title,
      "description": encodeURI(this.description),
      "isactive": this.activate,
      "url": this.url,
      "expirationdate": this.expiryDate,
      "published": this.activate

    }
    /*
        let selectedCategories = [];
    
        this.allCategories.forEach(element => {
    
          if (element.selected == true) {
            selectedCategories.push(element.id);
          }
    
        });
    
        this.parentCategories.forEach(element => {
    
          if (element.selected == true) {
            selectedCategories.push(element.id);
          }
    
        });
    
        postData["vcategory"] = selectedCategories;
    
    
        let selectedTerritories = [];
    
        this.selectedTerritories.forEach(element => {
    
          selectedTerritories.push(element.id);
    
        });
    
        postData["vterritories"] = selectedTerritories;
    */
    console.log("Post Data:" + JSON.stringify(postData));

    this.http.put(environment.strapiUrl + '/vpromotions/' + this.currentPromotionId, postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      if (this.selectedFile) {

        this.uploadOfferImage(data.id);

      } else {

        this.Loading = false;

        this.toasstService.showNotification('success', "Success", "Promotion updated successfully!");
        this.triggerEvent("true");

        this.bsModalRef.hide();

      }

    }
      ,
      (error) => {

        this.Loading = false;

        console.error('Error' + JSON.stringify(error));

        this.toasstService.showNotification('danger', "Error", "Something wrong happened, Please try again!");

      }
    );


  }


  deletePromotionModel() {

    this.deleteModal = this.modalService.show(this.modalDelete, this.default);

  }

  hidePromotionModel(): void {

    this.deleteModal.hide();


  }

  deletePromotion() {

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.delete(environment.strapiUrl + '/vpromotions/' + this.currentPromotion.id, { headers: reqHeader }).subscribe(response => {

      this.deleteModal.hide();

      this.toasstService.showNotification('danger', "Success", "Promotion deleted successfully!");
      this.triggerEvent("true");

      this.bsModalRef.hide();

    },
      (error) => {

        console.error('Error' + JSON.stringify(error.error));

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in deleting Advice and Tips, Please try again!");

      }
    );


  }


}
