import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { EventServiceService } from 'src/app/services/event-service.service';
import { tr } from 'date-fns/locale';

import mixpanel from 'mixpanel-browser';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  Loading: Boolean = false;

  currentUser: any;

  imgFile: string;
  imgSrc: any;
  selectedFileName: string;
  selectedFile: File;

  isCompleteProfile: boolean = true;

  vendorName: any;
  vendorDescription: any;
  vendorEmail: any;
  vendorPhone: any;
  vendorWebsite: any;

  newName: any;
  newDescription: any;
  newEmail: any;
  newPhone: any;
  newWebsite: any;
  vendorLogo: any;

  vendorAddress: any;
  vendorAddressId: any;

  newSuite: any;
  newStreetNumber: any;
  newStreetName: any;
  newCity: any;
  newState: any;
  newCountry: any;
  newPostalCode: any;
  addressText: any;

  displayAddress: boolean = true;

  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private mixpanelService: MixpanelEventServiceService
  ) {

    let user = JSON.parse(localStorage.getItem('user'));

    this.currentUser = user;

    /*
    
    this.vendorName = user.name;
   this.vendorEmail = user.email;
   this.vendorPhone = user.phone;
   this.vendorWebsite = user.website;
   this.vendorDescription = user.description;

   this.newName = user.name;
   this.newEmail = user.email;
   this.newPhone = user.phone;
   this.vendorWebsite = user.website;
   this.newDescription = user.description;



   if (user.profileimage && user.profileimage != null) {

     this.vendorLogo = user.profileimage.url;

   } else {

     this.vendorLogo = "assets/img/avatar-person.svg";

   }
*/

    this.getUserData();
    this.getVendorAddress();

    this.eventService.publish('UserProfile:updated', {
      result: true
    });

  }

  ngOnInit(): void {

    this.mixpanelService.identify(this.currentUser.id);
    this.mixpanelService.trackEvent("User Profile");

  }

  getUserData() {


    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vvendors/me', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      this.isCompleteProfile = data.iscompleteprofile;

      this.vendorName = data.name;
      this.vendorEmail = data.email;
      this.vendorPhone = data.phone;
      this.vendorWebsite = data.website;
      this.vendorDescription = data.description;

      this.newName = data.name;
      this.newEmail = data.email;
      this.newPhone = data.phone;
      this.newWebsite = data.website;
      this.newDescription = data.description;

      if (data.logo && data.logo.length > 0) {

        let logoLength = data.logo.length;

        this.vendorLogo = data.logo[logoLength - 1].url;

      }

      //localStorage.setItem('user', receivedData);

      this.Loading = false;

    });


  }

  getVendorAddress() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vvendoraddresses/me', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      console.log("Response:" + JSON.stringify(data));

      if (data.length > 0) {

        this.vendorAddress = data[0];

        this.vendorAddressId = this.vendorAddress.id;

        this.displayAddress = this.vendorAddress.displayaddress;

        this.newSuite = this.vendorAddress.suitenumber;
        this.newStreetNumber = this.vendorAddress.streetnumber;
        this.newStreetName = this.vendorAddress.streetname;
        this.newCity = this.vendorAddress.city;
        this.newState = this.vendorAddress.state;
        this.newCountry = this.vendorAddress.country;
        this.newPostalCode = this.vendorAddress.zipcode;

        this.addressText = this.vendorAddress.addresstext;

        console.log("Addresses:" + JSON.stringify(this.vendorAddress));

      }


      this.Loading = false;


    });

  }

  saveChages() {

    if (this.selectedFile) {

      this.uploadVendorImage();

    } else {

      this.updateVendorProfile();
    }


  }


  updateVendorProfile() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    if (this.newName && this.newEmail && this.newPhone && this.newWebsite && this.newDescription) {

      this.isCompleteProfile = true;

    } else {

      this.isCompleteProfile = false;

    }

    if (this.newDescription == null) {
      this.newDescription = "";
    }


    let postData = {

      "username": this.newPhone,
      "name": this.newName,
      "email": this.newEmail,
      "phone": this.newPhone,
      "website": this.newWebsite,
      "description": this.newDescription,
      "iscompleteprofile": this.isCompleteProfile

    }

    this.http.put(environment.strapiUrl + '/engaged/update', postData, { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData).vendor;

      console.log("Response:" + JSON.stringify(data));

      this.vendorName = data.name;
      this.vendorEmail = data.email;
      this.vendorPhone = data.phone;
      this.vendorWebsite = data.website;
      this.vendorDescription = data.description;

      this.newName = data.name;
      this.newEmail = data.email;
      this.newPhone = data.phone;
      this.vendorWebsite = data.website;
      this.newDescription = data.description;

      if (data.logo && data.logo.length > 0) {

        let logoLength = data.logo.length;

        this.vendorLogo = data.logo[logoLength - 1].url;

        //this.vendorLogo = data.logo[0].url;

      }

      localStorage.setItem('user', receivedData);

      this.eventService.publish('UserProfile:updated', {
        result: true
      });

      this.updateVendorAddress();

      this.Loading = false;


    });


  }


  updateVendorAddress() {

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    let addressText = "";

    if (this.newStreetNumber) {
      addressText += this.newStreetNumber + " ";
    }

    if (this.newStreetName) {
      addressText += this.newStreetName + " ";
    }

    if (this.newCity) {
      addressText += this.newCity + " ";
    }

    if (this.newState) {
      addressText += this.newState + " ";
    }

    if (this.newCountry) {
      addressText += this.newCountry + " ";
    }

    if (this.newPostalCode) {
      addressText += this.newPostalCode + " ";
    }

    let postData = {
      "suitenumber": this.newSuite,
      "streetnumber": this.newStreetNumber,
      "streetname": this.newStreetName,
      "city": this.newCity,
      "state": this.newState,
      "country": this.newCountry,
      "zipcode": this.newPostalCode,
      "addresstext": addressText,
      "displayaddress": this.displayAddress

    }

    if (this.vendorAddressId) {

      this.http.put(environment.strapiUrl + '/vvendoraddresses/' + this.vendorAddressId, postData, { headers: reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        this.vendorAddress = data;

        this.vendorAddressId = this.vendorAddress.id;

        this.newSuite = this.vendorAddress.suitenumber;
        this.newStreetNumber = this.vendorAddress.streetnumber;
        this.newStreetName = this.vendorAddress.streetname;
        this.newCity = this.vendorAddress.city;
        this.newState = this.vendorAddress.state;
        this.newCountry = this.vendorAddress.country;
        this.newPostalCode = this.vendorAddress.zipcode;

        this.addressText = this.vendorAddress.addresstext;

        this.Loading = false;

        console.log("Updataed Address:" + JSON.stringify(this.vendorAddress));

        this.toasstService.showNotification("success", "Success", "Vendor Profile Updated Successfully!");

      });
    }

    else {

      this.http.post(environment.strapiUrl + '/vvendoraddresses/me', postData, { headers: reqHeader }).subscribe(response => {

        let receivedData = JSON.stringify(response);
        let data = JSON.parse(receivedData);

        this.vendorAddress = data;

        this.vendorAddressId = this.vendorAddress.id;

        this.newSuite = this.vendorAddress.suitenumber;
        this.newStreetNumber = this.vendorAddress.streetnumber;
        this.newStreetName = this.vendorAddress.streetname;
        this.newCity = this.vendorAddress.city;
        this.newState = this.vendorAddress.state;
        this.newCountry = this.vendorAddress.country;
        this.newPostalCode = this.vendorAddress.zipcode;

        this.addressText = this.vendorAddress.addresstext;

        this.Loading = false;

        console.log("Updataed Address:" + JSON.stringify(this.vendorAddress));

        this.toasstService.showNotification("success", "Success", "Vendor Profile Updated Successfully!");

      });

    }


  }


  onImageChange(e) {

    this.imgFile = "";
    this.imgSrc = "";
    this.selectedFileName = "";

    this.selectedFile = e.target.files[0];

    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;

      console.log("File Name :" + JSON.stringify(e.target.files[0].name));

      reader.readAsDataURL(file);
      reader.onload = () => {

        this.imgFile = reader.result as string;
        this.imgSrc = reader.result;
        this.selectedFileName = e.target.files[0].name;


        console.log("Image File:" + this.imgFile);
        console.log("Image Src:" + this.imgSrc);

      };

    }
  }

  uploadVendorImage() {

    this.Loading = true;

    const ext = this.selectedFileName.substr(this.selectedFileName.lastIndexOf('.') + 1);
    const mimetype = this.getMimeType(ext);

    //let blobData = this.b64toBlob(this.imgFile, mimetype.type);

    const formData = new FormData();

    let data = {
      "name": "name"
    };

    formData.append('files.ProfileImage', this.selectedFile, this.selectedFileName);
    formData.append('data', JSON.stringify(data));


    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.put(environment.strapiUrl + '/engaged/updateprofileimage', formData, { headers: reqHeader }).subscribe(response => {

      this.imgFile = "";
      this.imgSrc = "";
      this.selectedFileName = "";
      this.selectedFile = null;

      this.updateVendorProfile();

    },
      (error) => {

        this.Loading = false;

        console.error('Error' + error);

        this.toasstService.showNotification('danger', "Error", "Sorry, Error occured in image upload, Please try again!");

      }
    );


  }

  getMimeType(fileExt) {

    if (fileExt == 'wav') return { type: `audio/wav` };
    else if (fileExt == 'pdf') return { type: `application/pdf` };
    else if (fileExt == 'jpg') return { type: `image/jpg` };
    else if (fileExt == 'mp4') return { type: `video/mp4` };
    else if (fileExt == 'MOV') return { type: `video/quicktime` };

  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


}
