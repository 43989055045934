import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventServiceService } from 'src/app/services/event-service.service';
import { MixpanelEventServiceService } from 'src/app/services/mixpanel-event-service.service';
import { ToastServiceService } from 'src/app/services/toast-service.service';
import { environment } from 'src/environments/environment';
import { ViewPromotionComponent } from '../view-promotion/view-promotion.component';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {
  
  Loading: Boolean = false;

  allPromotions = [];

  viewPromotionModal: BsModalRef;
  createPromotionModal: BsModalRef;
  
  constructor(
    private http: HttpClient,
    private toasstService: ToastServiceService,
    private eventService: EventServiceService,
    private router: Router,
    private mixpanelService: MixpanelEventServiceService,
    private modalService: BsModalService

  ) {

    this.getAllPromotions();

  }

  ngOnInit(): void {

    this.mixpanelService.trackEvent("View All Promotions");
    
  }

  getAllPromotions(){

    this.Loading = true;

    let token = localStorage.getItem('jwt');

    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });

    this.http.get(environment.strapiUrl + '/vpromotions', { headers: reqHeader }).subscribe(response => {

      let receivedData = JSON.stringify(response);
      let data = JSON.parse(receivedData);

      this.Loading = false;

      //console.log("Response:" + JSON.stringify(data));

      this.allPromotions = data;

    },
      (error) => {

        this.Loading = false;

        //console.error('Error' + error);

      }
    );



  }


  viewPromotion(promotion) {

    const initialState = {
      promotionData: [
        promotion
      ]
    };

    this.viewPromotionModal = this.modalService.show(ViewPromotionComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    this.viewPromotionModal.content.event.subscribe(res => {

      if (res.data == "true") {

        this.getAllPromotions();

      }
    });


  }

  createPromotion() {

    const initialState = {
      memberData: [
      ]
    };

    this.createPromotionModal = this.modalService.show(ViewPromotionComponent, { initialState, class: 'modal-xl', ignoreBackdropClick: true });

    this.createPromotionModal.content.event.subscribe(res => {
      if (res.data == "true") {

        this.getAllPromotions();

      }
    });

  }


}
